export const reasonOptions = [
  {
    OPTION_KEY: "price_is_better",
    OPTION_VAL: "Price is Better",
  },
  {
    OPTION_KEY: "other",
    OPTION_VAL: "Any Other",
  },
];
export const prev_policy_type_option = [
  {
    OPTION_KEY: "individual",
    OPTION_VAL: "individual",
  },
  {
    OPTION_KEY: "group",
    OPTION_VAL: "group",
  },
];
export const insuranceCompanyNames = [
  { OPTION_KEY: "star_health", OPTION_VAL: "Star Health Insurance" },
];
export const policy_type = [
  {
    OPTION_KEY: "floater",
    OPTION_VAL: "Floater",
  },
  {
    OPTION_KEY: "individual",
    OPTION_VAL: "Individual",
  },
];

export const continuous_coverage_year_options = [
  {
    OPTION_KEY: 1,
    OPTION_VAL: "1 year",
  },
  {
    OPTION_KEY: 2,
    OPTION_VAL: "2 year",
  },
  {
    OPTION_KEY: 3,
    OPTION_VAL: "3 year",
  },
  {
    OPTION_KEY: 4,
    OPTION_VAL: "4 year",
  },
];
