import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import api from "../api/api";
import { useTypedSelector } from "../hooks/useTypedSelector";
import useUrlQuery from "../hooks/useUrlQuery";
import { MembersInterface } from "../modules/landing.slice";
import { setActiveProposal, setProposalData } from "../modules/proposal.slice";
import { setSelectedRiders, setSelectedTenure } from "../modules/quotes.slice";
import { SelectOptions } from "../pages/ProposalPage/components/InputComponents/Select";
import { RiderInterface } from "./Quotes.service";
interface InitiateProposalRequest {
  PRODUCT_ID?: number;
  COMPANY_SLUG?: string;
  QUOTE_ID?: string;
  TOTAL_PREMIUM?: string;
  SUM_INSURED?: string;
  ADD_ONS?: RiderInterface[];
  TENURE?: string;
  NET_PREMIUM?: string;
  PORT_POLICY?: boolean;
  selected_members?: any;
}
interface InitiateProposalResponse {
  status: 200 | 500;
  response: {
    PROPOSAL_ID: string;
  };
}
export const useInitiateProposalMuation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutate, isLoading } = useMutation(
    (req: InitiateProposalRequest) => {
      return api.post<InitiateProposalResponse>(
        "api/InitiateHealthProposal",
        req
      );
    },
    {
      onSuccess: (res) => {
        dispatch(setSelectedRiders([]));
        navigate({
          pathname: "/product-summary",
          search: `?proposal_id=${res.data.response.PROPOSAL_ID}`,
        });
      },
    }
  );
  return {
    initiateProposal: mutate,
    isProposalInitiating: isLoading,
  };
};
interface ProposalDetailsResponse {
  status: 200 | 500;
  data: {
    PROPOSAL: {
      _id: string;
      QUOTE_ID: string;
      PRODUCT_ID: number;
      COMPANY_SLUG: string;
      TOTAL_PREMIUM: string;
      SUM_INSURED: string;
      updated_at: string;
      created_at: string;
      TENURE: string;
      ADD_ONS: RiderInterface[];
      FORMDATA: any;
      PORT_POLICY?: boolean;
      NET_PREMIUM: string;
    };
    QUOTE_REQUEST: {
      _id: string;
      pincode: string;
      mobile_no: string;
      members: MembersInterface[];
      policy_type: "I" | "F";
      requested_si: string;
      updated_at: string;
      created_at: string;
      proposer_gender: string;
    };
    product_data: {
      PRODUCTID: number;
      IC_ID: string;
      ADULT_MIN: string;
      ADULT_MAX: string;
      LOB: string;
      POLICY_TYPE: string;
      PRODUCT_TYPE: string;
      PRODUCT_NAME: string;
      PRODUCT_IDENTIFIER: string;
      CREATED_DATE: string;
      UPDATED_DATE: string;
      INSURANCE_NAME: string;
      INSURANCE_SLUG: string;
      ADDRESS: string;
      CONTACT_DETAILS: string;
      BROCHURE_LINK: string;
      LOGO_PATH: string;
      CHILD_MIN: number;
      CHILD_MAX: number;
    };
  };
}
export const useGetProposalDetailsQuery = () => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const dispatch = useDispatch();
  const { data, isLoading, isFetching } = useQuery(
    ["proposal", proposal_id],
    () =>
      api.post<ProposalDetailsResponse>("/api/GetHealthProposalDetails", {
        PROPOSAL_ID: proposal_id,
      }),
    {
      onSuccess: (data) => {
        if (data.data.data.PROPOSAL.ADD_ONS) {
          dispatch(setSelectedRiders(data.data.data.PROPOSAL.ADD_ONS));
          dispatch(setSelectedTenure(data.data.data.PROPOSAL.TENURE));
        }
        if (data.data.data.PROPOSAL.FORMDATA) {
          dispatch(setProposalData(data.data.data.PROPOSAL.FORMDATA));
        }
      },
    }
  );
  return {
    members: data?.data.data.QUOTE_REQUEST.members,
    enquiry: data?.data.data.QUOTE_REQUEST,
    quote_data: data?.data.data.PROPOSAL,
    product: data?.data.data.product_data,
    isCKYC: ["star", "manipal_cigna", "care"].includes(
      data?.data.data.product_data?.INSURANCE_SLUG || ""
    ),
    isPortability: data?.data.data.PROPOSAL.PORT_POLICY,
    isProposalLoading: isLoading || isFetching,
  };
};

export interface ProposalQuestions {
  ID: number;
  QUESTION_TITLE: string;
  QUESTION_ALIAS: string;
  IC_ID: 11;
  ANSWER_TYPE:
    | "BOOLEAN"
    | "DROPDOWN"
    | "FREE_TEXT"
    | "TOGGLE"
    | "DATE"
    | "MONTH_YEAR";
  PARENT_ID: string | null;
  PARENT_KEY: string | null;
  VALUE: SelectOptions[];
  RESTRICT_KEY: string | null;
  RESTRICT_OPERATOR: string | null;
  HAS_CHILD: "Y" | "N";
  CREATED_AT: string;
  UPDATED_AT: string;
  ADDITIONAL?: ProposalQuestions[];
  VIEWS?: Array<"year" | "month" | "day">;
  TEXTMASK?: string;
  MAXLENGTH?: number;
  ALLOW_ONLY_FUTURE?: "0" | "1";
  allow_multiple?: boolean;
}
interface ProposalQuestionsResponse {
  data: ProposalQuestions[];
  status: 200 | 500;
}
export const useGetProposalQuestions = () => {
  const { product } = useGetProposalDetailsQuery();
  const { getUrlQuery } = useUrlQuery();
  const PROPOSAL_ID = getUrlQuery("proposal_id");
  const { data } = useQuery(
    ["question", product?.IC_ID],
    () =>
      api.post<ProposalQuestionsResponse>("/api/GetHealthQADetails", {
        IC_ID: product?.IC_ID,
        PRODUCT_ID: product?.PRODUCTID,
        proposal_id: PROPOSAL_ID,
      }),
    {
      enabled: !!product?.IC_ID,
    }
  );

  return {
    questions: data?.data?.data,
  };
};

export const useUpdateProposalMutation = () => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (req: InitiateProposalRequest) =>
      api.post("api/UpdateHealthProposal", {
        ...req,
        PROPOSAL_ID: proposal_id,
      }),
    {
      onSuccess: (res) => {
        navigate({
          pathname: "/proposal",
          search: `?proposal_id=${proposal_id}`,
        });
        queryClient.removeQueries(["proposal"]);
      },
    }
  );

  return {
    updateProposal: mutate,
  };
};

export const useUpdateProposalFormData = (onSuccess = () => {}) => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { proposalData, activeProposal } = useTypedSelector(
    (state) => state.proposal
  );
  const { mutate, isLoading, isSuccess, mutateAsync } = useMutation(
    async (req: any) =>{
      const response = await api.post("api/UpdateRawHealthProposal", {
        PROPOSAL_ID: proposal_id,
        FORMDATA: {
          ...proposalData,
          ...req,
        },
      });
      if (response?.status === 500) {
        if (response?.data?.showpopup) {
          Swal.fire("Alert", response?.data?.message);
        }
      }
      return response;
    },
    {
      onSuccess: async () => {
        await api.post("api/RecalculateHealthPremium", {
          proposal_id,
        });
        queryClient.removeQueries(["proposal"]);
        dispatch(setActiveProposal(activeProposal + 1));
        onSuccess && onSuccess();
      },
      onError: (err: any) => {
        if (err?.response?.data?.status === 500) {
          if (err?.response?.data?.showpopup) {
            Swal.fire("Alert", err?.response?.data?.message);
          }
          else{
            toast.error(
               err?.response?.data?.message ||
                 "Something went wrong while submitting the proposal"
               );
          }
      }
    }
  }
  );
  return {
    saveForm: mutate,
    isSaving: isLoading,
    isSaved: isSuccess,
    asyncSaveForm: mutateAsync,
  };
};
interface PincodeRequest {
  pincode: string;
  company_alias?: string;
}
interface PincodeResponse {
  status: 200 | 500;
  response: {
    city: SelectOptions[];
    state: [SelectOptions];
  };
}
interface MasterResponse {
  status: 200 | 500;
  response: SelectOptions[];
}
export const useGetPincodeMutation = () => {
  const { isLoading, data, isError, mutate, isSuccess } = useMutation(
    ({ pincode, company_alias }: PincodeRequest) => {
      return api.post<PincodeResponse>("api/GetPincodeMaster", {
        pincode,
        company_alias,
      });
    },
    {
      onSettled: (data) => {
        if (data?.data.status === 500) {
          toast.error("Pincode not found");
        }
      },
    }
  );
  return {
    isCityStateLoading: isLoading,
    getCityState: mutate,
    cityOptions: data?.data?.response?.city,
    stateOptions: data?.data?.response?.state,
    isError,
    isCityStateFetched: isSuccess,
  };
};
export const useGetRelationMasterQuery = () => {
  const { quote_data } = useGetProposalDetailsQuery();
  const { isLoading, data } = useQuery(
    ["relation", quote_data?.COMPANY_SLUG],
    () => {
      return api.post<MasterResponse>("api/GetRelationMaster", {
        LOB: "HEALTH",
        COMPANY_SLUG: quote_data?.COMPANY_SLUG,
      });
    }
  );

  return {
    relations: data?.data?.response,
    isRelationsLoading: isLoading,
  };
};
export const useGetOccupationQuery = () => {
  const { quote_data } = useGetProposalDetailsQuery();
  console.log(quote_data,'quote_data')
  const { isLoading, data } = useQuery(
    
    ["occupation", quote_data?.COMPANY_SLUG],
    () => {
      console.log(data,"datadatadatadatadatadatadata")
      return api.post<MasterResponse>("api/GetOccupationMaster", {
        COMPANY_SLUG: quote_data?.COMPANY_SLUG,
        LOB: "HEALTH",
      });
    }
  );
  return {
    isOccupationLoading: isLoading,
    occupation_list: data?.data.response,
  };
};

export const serialize = function (obj: any) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};
export const useProposalFinalSubmitMutation = () => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const navigate = useNavigate();
  const { initiatePayment } = usePaymentQuery();
  const { isLoading, mutate, data } = useMutation(
    () => {
      return api.post("api/HealthProposalFinalSubmit", {
        PROPOSAL_ID: proposal_id,
      });
    },
    {
      onSuccess: (data) => {
        console.log(data.data.message,'dataaaamesaage')
        if (data.data.status === 500) {
          toast.error(data.data.message);
        }
        console.log(data.data)
        console.log(data.data.status)
        if (data.data.status === 200 || data.status === 200) {
          console.log(data,'data200')
          initiatePayment();
        }
        if (data?.data?.uw_data?.uw_flag) {
          Swal.fire(
            "NSTP",
            "This proposal will be sent for doctor approval. The policy will be converted only after approval."
          );
        }
        if (data?.data?.showpopup) {
          Swal.fire("Alert", data?.data?.message);
        }
      },
      onError: (err: any) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message ||
            "Something went wrong while submitting the proposal"
        );
      },
    }
  );
  return {
    isSubmitting: isLoading,
    submitProposal: mutate,
    data: data?.data,
  };
};
interface IOptions {
  onCKYCIncomplete?: () => void;
}
export const usePaymentQuery = (options?: IOptions) => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const navigate = useNavigate();
  const { isLoading, data, mutate } = useMutation(
    () => {
      return api.post("api/GetHealthPaymentDetails", {
        PROPOSAL_ID: proposal_id,
      });
    },
    {
      onSuccess: (data) => {
        if (data.data.status === 500) {
          toast.error(data.data.message);
        }
        if (data.data.status === 200) {
          const { PG_FORM, CKYC_LINK } = data.data;
          if (CKYC_LINK) {
            window.open(CKYC_LINK, "_blank");
            options?.onCKYCIncomplete && options?.onCKYCIncomplete();
            return;
          }
          const { fields, method, url } = PG_FORM;
          if (method === "RAZORPAY") {
            const options = url;
            console.log(options);
            // @ts-ignore
            const rzp1 = new window.Razorpay(options);
            rzp1.open();
            return;
          }
          if (method === "POST") {
            const form = document.createElement("form");
            form.name = "PAYMENTFORM";
            Object.keys(fields).forEach((item) => {
              const input = document.createElement("input");
              input.name = item;
              input.value = fields[item];
              form.appendChild(input);
            });
            form.action = url;
            form.method = method;
            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
          } else {
            let query: any;
            if (fields) {
              query = serialize(fields);
            }
            if (query) {
              window.open(url + "?" + query, "_self");
            } else window.open(url, "_self");
          }
        }
      },
    }
  );
  return {
    paymentData: data?.data,
    isPaymentLoading: isLoading,
    initiatePayment: mutate,
  };
};

export const useGetCouncilMasterQuery = () => {
  const { quote_data } = useGetProposalDetailsQuery();
  const { isLoading, data } = useQuery(
    ["medical_council", quote_data?.COMPANY_SLUG],
    () => {
      return api.post<any>("api/GetMedicalCouncilMaster", {
        LOB: "HEALTH",
        COMPANY_SLUG: quote_data?.COMPANY_SLUG,
      });
    }
  );

  return {
    council_names: data?.data?.response?.map((item: any) => ({
      OPTION_KEY: item?.COUNCIL_NAME,
      OPTION_VAL: item?.COUNCIL_NAME,
    })),
    isCouncilNamesLoading: isLoading,
  };
};
// Test the API manually
const testApi = async (ifscCode: string) => {
  try {
    const response = await api.get<any>("api/GetBankDetailsByIFSC", {
      params: { ifscCode: ifscCode },
    });
    console.log(response.data,"response.dataresponse.dataresponse.dataresponse.dataresponse.dataresponse.data");
  } catch (error) {
    console.error("Error fetching bank details:", error);
  }
};
testApi("YOUR_TEST_IFSC_CODE");


export const useGetBankDetailsByIFSC = (ifscCode?: string) => {
  const { isLoading, data, isError, error } = useQuery(
    ["bankdetails", ifscCode],
    async () => {
      if (!ifscCode) {
        throw new Error("IFSC code is required to fetch bank details");
      }

      console.log("Fetching bank details for IFSC code:", ifscCode);
      
      const response = await api.get<any>("api/GetBankDetailsByIFSC", {
        params: { ifscCode },
      });

      return response.data;
    },
    {
      enabled: !!ifscCode,
      retry: false,
    }
  );

  return {
    bankDetails: data,
    isBankDetailsLoading: isLoading,
    isError,
    error,
  };
};
