import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useGetProposalDetailsQuery } from "../../../../services/Proposal.service";
import FormLabel from "../FormLabel";
import CKYCCareForm from "./CKYCCareForm";
import CKYCCignaForm from "./CKYCCignaForm";
import CKYCStarForm from "./CKYCStarForm";

const CKYCForm = () => {
  const { activeProposal } = useTypedSelector((state) => state.proposal);
  const { product, isCKYC } = useGetProposalDetailsQuery();
  if (!isCKYC) return null;
  if (activeProposal !== 1) return null;
  return (
    <>
      <FormLabel label="Please fill the details below." />
      {product?.INSURANCE_SLUG === "star" && <CKYCStarForm />}
      {product?.INSURANCE_SLUG === "care" && <CKYCCareForm />}
      {product?.INSURANCE_SLUG === "manipal_cigna" && <CKYCCignaForm />}
    </>
  );
};

export default CKYCForm;
