import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import useUrlQuery from "../../../../hooks/useUrlQuery";
import { useGetQuote } from "../../../../services/Quotes.service";
import { Close } from "@mui/icons-material";
import {
  setPlansForCompare,
  updatePlansForCompare,
} from "../../../../modules/quotes.slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const SelectedCompare = () => {
  const { selected_plan_for_compare_product_ids } = useTypedSelector(
    (state) => state.quote
  );
  const dispatch = useDispatch();
  const { getUrlQuery } = useUrlQuery();
  const quote_id = getUrlQuery("quote_id");
  const navigate = useNavigate();
  if (!selected_plan_for_compare_product_ids.length) return <></>;
  return (
    <>
      <Box
        display="flex"
        position={"fixed"}
        bottom={0}
        height="100px"
        p={"20px"}
        backgroundColor="#f1f2f6"
        left={0}
        width={"100%"}
        boxShadow={"0 4px 8px 0 rgba(0,0,0,.4)"}
        borderTop={"1px solid #e3e4e8"}
      >
        <Grid container spacing={2}>
          {[0, 1, 2].map((item) => (
            <Grid item xs={3}>
              <SelectedProduct
                selected_plan={selected_plan_for_compare_product_ids[item]}
              />
            </Grid>
          ))}
          <Grid item xs={3}>
            <Box
              width={"100%"}
              justifyContent={"space-evenly"}
              display={"flex"}
              height={"100%"}
              alignItems={"center"}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  navigate(`/compare?quote_id=${quote_id}`);
                }}
              >
                Compare
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch(setPlansForCompare([]));
                }}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SelectedCompare;

const SelectedProduct = ({ selected_plan }) => {
  const { getUrlQuery } = useUrlQuery();
  const quote_id = getUrlQuery("quote_id");
  const endpoint = selected_plan
    ? `/api/GetHealthQuotes/${selected_plan?.slug}/${selected_plan?.product_id}/${quote_id}/${selected_plan?.cover_amount}`
    : "";
  const { premium_data, product_data } = useGetQuote(endpoint);
  const dispatch = useDispatch();
  return (
    <Box
      width={"100%"}
      height={"72px"}
      display={"flex"}
      sx={{
        border: "1px solid #ccc",
        p: 1,
        borderRadius: "8px",
        gap: "8px",
        position: "relative",
      }}
    >
      {selected_plan ? (
        <>
          <Box>
            <img
              width={"100px"}
              height={"auto"}
              src={product_data?.LOGO_PATH}
              alt="compare"
            />
          </Box>
          <Stack>
            <Typography color="#111" fontSize={"14px"}>
              {product_data?.PRODUCT_NAME}
            </Typography>
            <Typography color="text.primary" fontSize={"12px"}>
              {premium_data.COVER_AMOUNT}
            </Typography>
          </Stack>
          <IconButton
            onClick={() => {
              dispatch(updatePlansForCompare(selected_plan));
            }}
            size="small"
            sx={{
              position: "absolute",
              right: "-12px",
              top: "-12px",
              background: "white",
              "&:hover": {
                background: "white",
              },
            }}
          >
            <Close fontSize="small" />
          </IconButton>
        </>
      ) : (
        <>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
          >
            Select Plan
          </Box>
        </>
      )}
    </Box>
  );
};
