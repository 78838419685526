import { ButtonGroup } from "@mui/material";
import { feetOptions, inchesOptions } from "../../options.utils";
import Select from "./Select";
interface HeightProps {
  name?: string;
  readOnly?: boolean;
}
const Height: React.FC<HeightProps> = ({ name, readOnly }) => {
  return (
    <ButtonGroup fullWidth>
      <Select
        label='Feet'
        name={name ? `${name}.feet` : "feet"}
        options={feetOptions}
        readOnly={readOnly}
      />
      <Select
        label='Inches'
        name={name ? `${name}.inches` : "inches"}
        options={inchesOptions}
        readOnly={readOnly}
      />
    </ButtonGroup>
  );
};

export default Height;
