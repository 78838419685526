import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      light: "#D3ECF1",
      main: "#016D91",
      contrastText: "#fff",
    },
    secondary: {
      main: "#F05B23",
    },
    text: {
      primary: "#747A9B",
      secondary: "#2D2C5F",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontSize: "28px",
      fontWeight: 400,
      color: "#2D2C5F",
    },
    body1: {
      fontSize: "18px",
      fontWeight: 500,
      color: "#747A9B",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.02)",
          borderRadius: "5px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          color: "#747A9B",
          fontWeight: 500,
          marginTop: "6px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          textTransform: "none",
          fontWeight: 400,
        },
        contained: {
          boxShadow: "none",
        },
      },
    },
  },
});

export default theme;
