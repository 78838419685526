import { Paper, SxProps } from "@mui/material";
import React from "react";

const ShadowBox: React.FC<{
  children: React.ReactNode;
  sx?: SxProps;
  br?: boolean;
  p?: object | number | string;
  px?: object | number | string;
  py?: object | number | string;
}> = ({ sx, children, br, p, px, py }) => {
  return (
    <>
      <Paper
        sx={{
          boxShadow: "0px 15px 20px 5px rgba(219, 219, 219, 0.25)",
          borderRadius: br ? "5px" : "0px",
          p: p ? p : 0,
          px: px ? px : 0,
          py: py ? py : 0,
          ...sx,
        }}
      >
        {children}
      </Paper>
    </>
  );
};

export default ShadowBox;
