import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import SelectMenu from "../../../../components/SelectMenu/SelectMenu";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import {
  closeFilterModal,
  openFilterModal,
} from "../../../../modules/quotes.slice";
import CompanyFilter from "./components/CompanyFilter";
import { FilterItem } from "./components/FilterItem";
import FilterModal from "./components/FilterModal";
import { deductibleOptions, sumInsuredOptions } from "./Filters.utils";

const Filters = () => {
  const { requested_si, deductible, existing_cover } = useTypedSelector(
    (state) => state.quote.filter_modal
  );
  const { existing_health_plan } = useTypedSelector((state) => state.landing);
  const isTopUp = existing_health_plan === "Y";
  const {
    requested_si: requested_si_value,
    preferred_insurer: preferred_insurer_value,
    deductible: deductible_value,
    existing_cover: existing_cover_value,
  } = useTypedSelector((state) => state.quote.filters);
  const dispatch = useDispatch();
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          boxShadow: "0px 8px 10px 5px rgba(252, 252, 252, 0.25)",
          borderRadius: "5px 5px 0px 0px",
          backgroundColor: "white",
          py: 1.5,
          px: 2,
          "@media (max-width: 600px)": {
            flexWrap: "wrap",
          },
        }}
      >
        <SelectMenu
          selectedValue={
            sumInsuredOptions.find((item) => item.value === requested_si_value)
              ?.label
          }
          onClick={() => {
            dispatch(
              openFilterModal({
                key: "requested_si",
              })
            );
          }}
          label="Sum Assured"
        />
        <FilterModal
          title="Sum Assured"
          maxWidth="xs"
          open={requested_si}
          onClose={() => {
            dispatch(
              closeFilterModal({
                key: "requested_si",
              })
            );
          }}
        >
          {sumInsuredOptions.map((item) => (
            <>
              <FilterItem {...item} name="requested_si" />
            </>
          ))}
        </FilterModal>
        <SelectMenu
          selectedValue={
            preferred_insurer_value.length
              ? `${preferred_insurer_value.length} Companies`
              : "All Insurance"
          }
          onClick={() => {
            dispatch(
              openFilterModal({
                key: "preferred_insurer",
              })
            );
          }}
          label="Preferred Brands"
        />
        <CompanyFilter />

        <SelectMenu
          selectedValue={
            deductibleOptions.find((item) => item.value === deductible_value)
              ?.label
          }
          onClick={() => {
            dispatch(
              openFilterModal({
                key: "deductible",
              })
            );
          }}
          label="Deductibles"
        />
        <FilterModal
          title="Deductibles"
          maxWidth="xs"
          open={deductible}
          onClose={() => {
            dispatch(
              closeFilterModal({
                key: "deductible",
              })
            );
          }}
        >
          {deductibleOptions.map((item) => (
            <>
              <FilterItem {...item} name="deductible" />
            </>
          ))}
        </FilterModal>
        <SelectMenu
          selectedValue="No Preferance"
          onClick={() => {
            "hello";
          }}
          label="PED Waiting period"
        />
        <SelectMenu
          selectedValue="All"
          onClick={() => {
            "hello";
          }}
          label="Special Features"
        />
      </Box>
    </>
  );
};
export default Filters;
