import { Box, Container, Grid, Paper, Stack, Typography } from "@mui/material";

import Stepper from "../../components/Stepper/Stepper";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import AgeForm from "./forms/AgeForm";
import MemberForm from "./forms/MemberForm";
import PersonalForm from "./forms/PersonalForm";
import money from "./../../assets/images/savings.jpg";
import health from "./../../assets/images/health-insurance.jpg";
import ExistingIllness from "./forms/ExistingDisease";
const ActiveForm = () => {
  const { activeStep } = useTypedSelector((state) => state.landing);
  switch (activeStep) {
    case 0:
      return <MemberForm />;
    case 1:
      return <AgeForm />;
    case 2:
      return <ExistingIllness />;
    case 3:
      return <PersonalForm />;
    default:
      return <></>;
  }
};

const Landing = () => {
  const { activeStep } = useTypedSelector((state) => state.landing);
  return (
    <Container>
      <Grid container>
        <Grid item md={9} sm={9} xs={12}>
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minHeight: "520px",
              gap: "30px",
              mt: 2,
            }}
          >
            <Box my={2}>
              <Stepper activeStep={activeStep} />
            </Box>

            <ActiveForm />
          </Paper>
        </Grid>
        <Grid item md={3} sm={3} xs={12}>
          <Stack mt={3} spacing={2} pl={1}>
            <Box
              sx={{
                background: "#c8efff",
                padding: "20px 12px",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
                "& img": {
                  margin: "auto",
                  mixBlendMode: "multiply",
                },
              }}
            >
              <img src={money} width="80px" alt="info" />
              <Typography fontSize={"16px"} color="text.secondary">
                A health insurance plan also saves you upto ₹75,000 under 80D*
              </Typography>
              <Box>
                <Typography fontSize={"15px"} gutterBottom>
                  * Upto ₹25,000 for you, spouse & kids{" "}
                </Typography>
                <Typography fontSize={"15px"}>
                  * Additionally, upto ₹50,000 for your senior citizen parents{" "}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                background: "#e0ffe9",
                padding: "20px 12px",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
                "& img": {
                  margin: "auto",
                  mixBlendMode: "multiply",
                },
              }}
            >
              <img src={health} width="120px" alt="info" />
              <Typography fontSize={"16px"} color="text.secondary">
                Get Insured Early as your health insurance premium increases
                with age.
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Landing;
