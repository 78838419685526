import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HeartLoader from "../../../components/Loader/Loader";
import ShadowBox from "../../../components/ShadowBox/ShadowBox";
import PairData, {
  PairDataWithoutBox,
} from "../../../components/SummaryComponents/PairData";
import Title from "../../../components/SummaryComponents/Title";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
  useGetProposalDetailsQuery,
  useProposalFinalSubmitMutation,
  useUpdateProposalMutation,
} from "../../../services/Proposal.service";
import { commaSeparateNumber } from "../../../utils/priceUtils";

const ProductSummary: React.FC = () => {
  const { quote_data, product } = useGetProposalDetailsQuery();
  const { selectedRiders, selectedTenure, selected_members_rider } =
    useTypedSelector((state) => state.quote);
  const location = useLocation();
  const isProposal = location.pathname === "/proposal";
  const isProposalSummary = location.pathname.includes("proposal-summary");
  const { updateProposal } = useUpdateProposalMutation();
  const [portPolicy, setPortPolicy] = useState(false);
  const onProceed = () => {
    updateProposal({
      ADD_ONS: selectedRiders,
      TENURE: selectedTenure,
      TOTAL_PREMIUM: quote_data?.TOTAL_PREMIUM,
      NET_PREMIUM: quote_data?.TOTAL_PREMIUM,
      PORT_POLICY: portPolicy,
      selected_members: selected_members_rider,
    });
  };

  const { submitProposal, isSubmitting } = useProposalFinalSubmitMutation();

  function loadRazorpayScript(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  async function displayRazorpayPaymentSdk() {
    const res = await loadRazorpayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. please check are you online?");
      return;
    }
  }

  useEffect(() => {
    displayRazorpayPaymentSdk();
  }, []);
  const onProceedToPayment = () => {
    submitProposal();
  };
  console.log({ selectedRiders });
  const additionalPremium = selectedRiders.reduce(
    (acc, curr: any) => acc + (curr?.IS_MANDETORY === "Y" ? 0 : +curr.premium),
    0
  );
  const mandatoryPremium = selectedRiders.reduce(
    (acc, curr: any) => acc + (curr?.IS_MANDETORY === "Y" ? +curr.premium : 0),
    0
  );
  return (
    <ShadowBox
      px={1}
      py={2}
      br
      sx={{
        position: "sticky",
        top: 0,
      }}
    >
      <Stack spacing={1}>
        <Title title="Summary" />
        {isSubmitting && <HeartLoader />}
        {isProposal && (
          <ShadowBox px={1}>
            <Box display="flex" gap="18px" alignItems={"center"}>
              <img
                src={product?.LOGO_PATH}
                alt=""
                height={"60px"}
                style={{
                  maxWidth: "120px",
                }}
              />
              <Typography fontSize={"15px"}>{product?.PRODUCT_NAME}</Typography>
            </Box>
          </ShadowBox>
        )}

        {!isProposalSummary && (
          <>
            <PairData
              label="Premium"
              value={
                quote_data?.COMPANY_SLUG === "manipal_cigna"
                  ? commaSeparateNumber(
                      Number(quote_data?.NET_PREMIUM) - mandatoryPremium
                    )
                  : commaSeparateNumber(Number(quote_data?.NET_PREMIUM))
              }
            />
            <PairData
              label="Cover"
              value={commaSeparateNumber(quote_data?.SUM_INSURED)}
            />
            <PairData label="Policy Period" value={`${selectedTenure} year`} />
          </>
        )}
        <Title title="Riders">
          {selectedRiders.length ? (
            selectedRiders.map((rider) => (
              <PairDataWithoutBox
                label={rider.ADD_ON_NAME}
                value={commaSeparateNumber(rider.premium)}
              />
            ))
          ) : (
            <>
              <Typography align="center" fontSize="14px" gutterBottom>
                No riders selected.
              </Typography>
            </>
          )}
        </Title>
        {!isProposal &&
          !isProposalSummary &&
          ["star", "care", "niva_bupa"].includes(
            product?.INSURANCE_SLUG || ""
          ) && (
            <Title title="Port Existing Policy">
              <FormControlLabel
                checked={portPolicy}
                onChange={(e, checked) => {
                  setPortPolicy(checked);
                }}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                  },
                }}
                control={<Checkbox size="small" />}
                label="I want to port my existing policy."
              />
            </Title>
          )}
        <PairData
          primary
          label="Total Premium"
          value={commaSeparateNumber(
            Number(quote_data?.NET_PREMIUM) + Number(additionalPremium)
          )}
        />
        {!isProposal && !isProposalSummary && (
          <Button variant="contained" size="large" onClick={onProceed}>
            Proceed to Proposal
          </Button>
        )}
        {isProposalSummary && product?.INSURANCE_SLUG && (
          <Button variant="contained" size="large" onClick={onProceedToPayment}>
            Proceed to Payment
          </Button>
        )}
      </Stack>
    </ShadowBox>
  );
};

export default ProductSummary;
