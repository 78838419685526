import { createSlice } from "@reduxjs/toolkit";
export interface MembersInterface {
  age: number;
  gender?: "male" | "female";
  relation: "self" | "spouse" | "son" | "daughter" | "father" | "mother";
  count?: number;
}
const landingSlice = createSlice({
  name: "landing",
  initialState: {
    gender: "male" as MembersInterface["gender"] | undefined,
    members: [] as MembersInterface[],
    activeStep: 0,
    pincode: "",
    mobile_no: "",
    existing_health_plan: "N",
    disease_details: [] as number[],
  },
  reducers: {
    setGender: (state, action) => {
      state.gender = action.payload;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setMemberDetails: (state, action) => {
      state.members = action.payload;
    },
    setPersonalDetails: (state, action) => {
      state.pincode = action.payload.pincode;
      state.mobile_no = action.payload.mobile_no;
      state.existing_health_plan = action.payload.existing_health_plan;
    },
    setDiseaseDetails: (state, action) => {
      state.disease_details = action.payload;
    },
  },
});

export const {
  setGender,
  setActiveStep,
  setMemberDetails,
  setPersonalDetails,
  setDiseaseDetails,
} = landingSlice.actions;
export default landingSlice.reducer;
