import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import ShadowBox from "../../../components/ShadowBox/ShadowBox";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
  useGetOccupationQuery,
  useGetProposalDetailsQuery,
} from "../../../services/Proposal.service";
import { reduceOptionsValue } from "../../../utils/inputUtils";
import ProposalData from "./ProposalData";

const InsurerDetails = () => {
  const { proposalData } = useTypedSelector((state) => state.proposal);
  const { members } = useGetProposalDetailsQuery();
  const { occupation_list } = useGetOccupationQuery();
  const insurer_details = proposalData?.insurer_details;
  if (!insurer_details) return <></>;
  return (
    <>
      {" "}
      <ShadowBox px={2} py={2}>
        <Box>
          <Typography
            color='text.secondary'
            fontSize={{
              xs: "12px",
              md: "16px",
            }}
            mb={2}
          >
            Insurer Details
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {members?.map((member) => {
            const key_name = member.count
              ? member.relation + member.count
              : member.relation;
            return (
              <>
                {" "}
                <Grid item xs={12}>
                  <Typography
                    color='text.secondary'
                    textTransform={"capitalize"}
                    fontWeight='500'
                    fontSize={{
                      xs: "12px",
                      md: "16px",
                    }}
                  >
                    {member.relation}
                  </Typography>
                </Grid>
                <ProposalData
                  label='First Name'
                  value={insurer_details[key_name].first_name}
                />
                <ProposalData
                  label='Last Name'
                  value={insurer_details[key_name].last_name}
                />
                <ProposalData
                  label='Date of Birth'
                  value={moment(insurer_details[key_name].dob).format(
                    "DD/MM/YYYY"
                  )}
                />
                <ProposalData
                  label='Occupation'
                  value={reduceOptionsValue(
                    occupation_list,
                    insurer_details[key_name].occupation
                  )}
                />
                <ProposalData
                  label='Height'
                  value={`${insurer_details[key_name].feet}'${insurer_details[key_name].inches}"`}
                />
                <ProposalData
                  label='Weight'
                  value={insurer_details[key_name].weight}
                />
              </>
            );
          })}
        </Grid>
      </ShadowBox>
    </>
  );
};

export default InsurerDetails;
