import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";
export interface QuoteDataProps {
  label: string;
  value: string | undefined | React.ReactNode;
  isLoading?: boolean;
}

const QuoteData: React.FC<QuoteDataProps> = ({ label, value, isLoading }) => {
  return (
    <>
      <Box
        display={"flex"}
        flexDirection='column'
        gap={{
          xs: "0px",
          sm: "8px",
        }}
      >
        <Typography
          fontSize={{
            xs: "10px",
            sm: "16px",
          }}
          sx={{
            color: "#eaeaea",
          }}
          fontWeight={"400"}
        >
          {label}
        </Typography>
        {isLoading || !value ? (
          <Skeleton variant='text' width={"100%"} />
        ) : (
          <Typography
            fontSize={{ xs: "12px", sm: "18px" }}
            lineHeight={"15px"}
            fontWeight={"500"}
            sx={{
              wordBreak: "break-word",
              color: "#fff",
            }}
          >
            {value}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default QuoteData;
