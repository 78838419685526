import { ProposalQuestions } from "../../services/Proposal.service";
import store from "../../app/store";
import moment from "moment";
export class ValidationFactory {
  private errors: any = {};

  validate = (
    questions: ProposalQuestions[] | undefined,
    member?: string,
    parent?: string
  ) => {
    return (values: any) => {
      if (!parent) {
        this.errors = {};
      }
      questions?.forEach((item) => {
        this.validateField(item, values, member, parent);
      });

      return this.errors;
    };
  };

  validateField = (
    field: ProposalQuestions,
    values: any,
    member?: string,
    parent?: string
  ) => {
    const name = field.QUESTION_ALIAS;

    if (field.ANSWER_TYPE === "BOOLEAN") {
      if (!values[name]?.members) {
        this.errors = {
          ...this.errors,
          [name]: { members: "This Field is Required" },
        };
      }
      if (field.RESTRICT_KEY === "Y" && values[name]?.members.length) {
        this.errors = {
          ...this.errors,
          [name]: { members: "You are not allowed to purchase this policy" },
        };
      }
      if (values[name]?.members?.length && field.ADDITIONAL) {
        values[name]?.members?.forEach((item: string) => {
          this.validate(field.ADDITIONAL, item, name)(values);
        });
      }
    }
    if (field.ANSWER_TYPE === "MONTH_YEAR" || field.ANSWER_TYPE === "DATE") {
      if (parent && member) {
        if (
          !values[parent] ||
          !values[parent][member] ||
          !values[parent][member][name]
        ) {
          this.errors = {
            ...this.errors,
            [parent]: {
              ...(this.errors[parent] ? this.errors[parent] : {}),
              [member]: {
                ...(this.errors[parent]?.[member]
                  ? this.errors[parent][member]
                  : {}),
                [name]: "This Field is Required",
              },
            },
          };
        } else if (values[parent][member][name]) {
          const value = values[parent][member][name];
          const dob =
            store.getState().proposal?.proposalData?.insurer_details[member]
              ?.dob;
          const isBeforeDob = moment(value, "YYYY-MM-DD").isBefore(
            moment(dob, "YYYY-MM-DD")
          );
          const isFutureDate = moment(value, "YYYY-MM-DD").isAfter(moment());
          if (field.ALLOW_ONLY_FUTURE === "1") {
            if (!isFutureDate) {
              this.errors = {
                ...this.errors,
                [parent]: {
                  ...(this.errors[parent] ? this.errors[parent] : {}),
                  [member]: {
                    ...(this.errors[parent]?.[member]
                      ? this.errors[parent][member]
                      : {}),
                    [name]: "This cannot be a past date",
                  },
                },
              };
            }
          } else if (isBeforeDob || isFutureDate) {
            this.errors = {
              ...this.errors,
              [parent]: {
                ...(this.errors[parent] ? this.errors[parent] : {}),
                [member]: {
                  ...(this.errors[parent]?.[member]
                    ? this.errors[parent][member]
                    : {}),
                  [name]: isBeforeDob
                    ? "This cannot be before the date of birth"
                    : "This cannot be a future date",
                },
              },
            };
          }
        }
      } else if (!values[name]) {
        this.errors = {
          ...this.errors,
          [name]: "This Field is Required",
        };
      }
    }
    if (field.ANSWER_TYPE === "TOGGLE") {
      if (parent && member) {
        if (
          !values[parent] ||
          !values[parent][member] ||
          !values[parent][member][name]
        ) {
          this.errors = {
            ...this.errors,
            [parent]: {
              ...(this.errors[parent] ? this.errors[parent] : {}),
              [member]: {
                ...(this.errors[parent]?.[member]
                  ? this.errors[parent][member]
                  : {}),
                [name]: "This Field is Required",
              },
            },
          };
        }
        if (values[parent][member][name] === "Y" && field.ADDITIONAL) {
          this.validate(field.ADDITIONAL, member, parent)(values);
          return;
        }
      } else if (!values[name]) {
        this.errors = {
          ...this.errors,
          [name]: "This Field is Required",
        };
      }
      if (values[name] === "Y" && field.ADDITIONAL) {
        this.validate(field.ADDITIONAL, member, name)(values);
      }
    }
    if (field.ANSWER_TYPE === "DROPDOWN" || field.ANSWER_TYPE === "FREE_TEXT") {
      if (parent && member) {
        if (
          !values[parent] ||
          !values[parent][member] ||
          !values[parent][member][name]
        ) {
          this.errors = {
            ...this.errors,
            [parent]: {
              ...(this.errors[parent] ? this.errors[parent] : {}),
              [member]: {
                ...(this.errors[parent]?.[member]
                  ? this.errors[parent][member]
                  : {}),
                [name]: "This Field is Required",
              },
            },
          };
        } else if (values[parent][member][name]) {
          const value = values[parent][member][name];
          const containsAlphabet = /([A-Z])/i.test(value);
          const containsNumber = /([0-9])/.test(value);
          if (!containsAlphabet && field.TEXTMASK === "ALPHANUMERICCUSTOM") {
            this.errors = {
              ...this.errors,
              [parent]: {
                ...(this.errors[parent] ? this.errors[parent] : {}),
                [member]: {
                  ...(this.errors[parent]?.[member]
                    ? this.errors[parent][member]
                    : {}),
                  [name]: "Field must contain alphabets",
                },
              },
            };
          }
          if (!containsNumber && field.TEXTMASK === "DOSAGE") {
            this.errors = {
              ...this.errors,
              [parent]: {
                ...(this.errors[parent] ? this.errors[parent] : {}),
                [member]: {
                  ...(this.errors[parent]?.[member]
                    ? this.errors[parent][member]
                    : {}),
                  [name]: "Field must contain numbers",
                },
              },
            };
          }
        }
      } else if (!values[name]) {
        this.errors = {
          ...this.errors,
          [name]: "This Field is Required",
        };
      }
    }
  };
  getErrors = () => {
    return this.errors;
  };
}
