import { Container, Grid, Link, Stack, Box, Typography } from "@mui/material";
import React from "react";

import irdai from "./../../assets/images/irdai.png";
import phone from "./../../assets/images/phone.png";
import address from "./../../assets/images/address.png";
import { Link as RouterLink } from "react-router-dom";

const Footer = () => {
  const links = [
    { name: "Car Insurance", link: "https://account.vkover.com" },
    {
      name: "Bike Insurance",
      link: "https://account.vkover.com",
    },
    {
      name: "Health Insurance",
      link: "https://account.vkover.com",
    },
    {
      name: "Term Insurance",
      link: "https://account.vkover.com",
    },
  ];
  const quickLinks = [
    { name: "Home", link: "https://yellainsure.com/", isLocal: true },
    {
      name: "About Us",
      link: "https://yellapolicy.com/about-us",
      isLocal: true,
    },
    {
      name: "Claim",
      link: "https://yellapolicy.com/claim",
    },
    {
      name: "Policy Cancellation & Refund",
      link: "https://yellapolicy.com/policy-cancellation-refund",
    },
    {
      name: "Careers",
      link: "https://vkover.com/careers",
    },
    {
      name: "PoSP Agreement",
      link: "https://api.vkover.com/storage/posp-agreement.docx",
      isDownload: true,
    },
    {
      name: "Greviance Policy",
      link: "https://yellapolicy.com/greviance-policy",
      isLocal: true,
    },
  ];
  const licenseInfo = [
    "CIN: U66000KL2019PTC058280",
    "IRDAI License No.706",
    "Composite Broker(Life & General)",
    "Valid up to 25/04/2026",
    "Principal Officer - K . H Devaki",
    "+91 0484 4037917",
  ];
  return (
    <>
      <div className='footer-section'>
        <Container
          sx={{
            pt: 5,
            pb: 1,
          }}
        >
          <Grid container spacing={5}>
            <Grid item md={3} sm={6} xs={6}>
              <Typography
                variant='body1'
                fontSize={"20px"}
                color='#fff'
                fontWeight={500}
                mb={2}
              >
                Get Insured
              </Typography>
              <Stack spacing={2}>
                {links.map((item) => (
                  <>
                    <Link
                      color={"#fff"}
                      sx={{
                        fontSize: "13px !important",
                      }}
                      href={item.link}
                    >
                      {item.name}
                    </Link>
                  </>
                ))}
              </Stack>
            </Grid>
            <Grid item md={3} sm={6} xs={6}>
              <Typography
                variant='body1'
                fontSize={"20px"}
                color='#fff'
                fontWeight={500}
                mb={2}
              >
                Quick Links
              </Typography>
              <Stack spacing={2}>
                {quickLinks.map((item) => (
                  <>
                    <Link
                      color={"#fff"}
                      sx={{
                        fontSize: "13px !important",
                      }}
                      download={item.isDownload}
                      href={item.link}
                    >
                      {item.name}
                    </Link>
                  </>
                ))}
              </Stack>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <img src={irdai} alt='' />
              <Stack spacing={2} mt={2}>
                {licenseInfo.map((item) => (
                  <Typography variant='body1' fontSize={"13px"} color='#fff'>
                    {item}
                  </Typography>
                ))}
              </Stack>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <Typography
                variant='body1'
                fontSize={"20px"}
                color='#fff'
                fontWeight={500}
                mb={2}
              >
                Contact
              </Typography>
              <Stack
                spacing={2}
                sx={{
                  "@media (max-width: 768px)": {
                    display: "flex",
                    flexDirection: "column",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "12px",
                  }}
                >
                  <img src={phone} width='40px' height='40px' />
                  <Stack>
                    <Link
                      color={"#fff"}
                      fontFamily='400'
                      sx={{
                        fontSize: "13px !important",
                      }}
                      href='tel:+919288009111'
                    >
                      +91 92880 09111
                    </Link>
                    <Link
                      color={"#fff"}
                      fontFamily='400'
                      sx={{
                        fontSize: "13px !important",
                      }}
                      href='mailto:support@vkover.com'
                    >
                      contact@vkover.com
                    </Link>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "12px",
                  }}
                >
                  <img src={address} width='40px' height='40px' />
                  <Stack>
                    <Typography variant='body1' fontSize={"13px"} color='#fff'>
                      2nd Floor,Mariyam Tower
                    </Typography>
                    <Typography variant='body1' fontSize={"13px"} color='#fff'>
                      K K Road,Ernakulam,Kerala
                    </Typography>
                    <Typography variant='body1' fontSize={"13px"} color='#fff'>
                      682 017
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Typography
            variant='body1'
            textAlign={"center"}
            fontSize={"13px"}
            color='#fff'
            mt={5}
          >
            Insurance is the subject matter of the solicitation. For more
            details on policy terms, conditions, exclusions, limitations, read
            policy before concluding sale.
          </Typography>
          <Grid container justifyContent={"space-between"} mt={2}>
            <Grid item>
              <Typography
                variant='body1'
                textAlign={"center"}
                fontSize={"13px"}
                color='#fff'
              >
                © 2024 Yella, All Rights Reserved.
              </Typography>
            </Grid>
            <Grid item>
              <Link
                color={"#fff"}
                fontFamily='400'
                sx={{
                  fontSize: "13px !important",
                  textDecoration: "underline",
                  mr: 2,
                }}
                href='https://www.vkover.com/privacy-policy'
              >
                Privacy Policy
              </Link>
              <Link
                color={"#fff"}
                fontFamily='400'
                sx={{
                  fontSize: "13px !important",
                  textDecoration: "underline",
                }}
                href='https://www.vkover.com/terms-and-conditions'
              >
                Terms & Conditions
              </Link>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Footer;
