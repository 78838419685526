import { useQuery } from "@tanstack/react-query";
import api from "../api/api";
import useUrlQuery from "../hooks/useUrlQuery";

export const useGetPolicyDetails = () => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const { data, isLoading } = useQuery(["getPolicyDetails"], () => {
    return api.post("api/GetHealthPolicyDetails", {
      PROPOSAL_ID: proposal_id,
    });
  });

  return {
    policy_data: data?.data?.POLICY_DATA[0],
    pdf: data?.data?.POLICY_DATA[0]?.POLICY_DOC,
    isPolicyLoading: isLoading,
  };
};
