import { Box, FormControlLabel, Radio } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { setFilters } from "../../../../../modules/quotes.slice";

interface FilterItemProps {
  label: string;
  value: string;
  name: "requested_si" | "deductible" | "existing_cover";
}

export const FilterItem: React.FC<FilterItemProps> = ({
  label,
  value,
  name,
}) => {
  const { filters } = useTypedSelector((state) => state.quote);
  const dispatch = useDispatch();
  return (
    <>
      <FormControlLabel
        sx={{
          display: "block",
          border: "1px solid #ECECEC",
          borderRadius: "5px",
          mb: 1,
          p: 1,
          mr: 0,
          background: value === filters[name] ? "rgba(1, 109, 145, 0.31)" : "",
          "& .MuiFormControlLabel-label": {
            color: value === filters[name] ? "#016D91" : "",
            fontSize: "14px",
          },
        }}
        label={label}
        value={value}
        checked={value === filters[name]}
        control={<Radio />}
        onChange={(e, checked) => {
          if (checked)
            dispatch(
              setFilters({
                key: name,
                value,
              })
            );
        }}
      />
    </>
  );
};
