import { SelectOptions } from "../pages/ProposalPage/components/InputComponents/Select";

export const allowOnlyNumbers = (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  event.target.value = event.target.value.replace(/[^0-9]/g, "");
};
export const allowOnlyDosage = (event: React.ChangeEvent<HTMLInputElement>) => {
  event.target.value = event.target.value.replace(/[^0-9.,\sA-Za-z]/g, "");
};
export const allowOnlyAlphaNumericCustom = (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  event.target.value = event.target.value.replace(/[^A-Za-z0-9.,\s]/g, "");
};

export const allowAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
  event.target.value = event.target.value.replace(/[^a-zA-Z0-9-\s./#&,]/g, "");
};
export const toCapitalize = (event: React.ChangeEvent<HTMLInputElement>) => {
  event.target.value = event.target.value
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const allowOneWord = (event: React.ChangeEvent<HTMLInputElement>) => {
  event.target.value = event.target.value.replace(/ /g, "");
};

export const allowOnlyAlphabets = (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  event.target.value = event.target.value.replace(/[^a-zA-Z ]/g, "");
};
export const allowOnlyAlphabetsAndNumbers = (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, "");
};

export const allowOnlyPanCard = (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  event.target.value = event.target.value
    .replace(/[^a-zA-Z0-9]/g, "")
    .toUpperCase();
};

export const regex = {
  mobile_no: /^[6-9]{1}[0-9]{9}$|^$/,
  pan_no: /^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$|^$/,
};

export const reduceOptionsValue = (
  options: SelectOptions[] | undefined,
  key: string
) => {
  if (!options) return "";
  return options.find((option) => option.OPTION_KEY === key)?.OPTION_VAL;
};
