import { Grid, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { ProposalQuestions } from "../../services/Proposal.service";
import componentSchema from "./componentSchema";
import moment from "moment";
import {
  allowOnlyAlphaNumericCustom,
  allowOnlyDosage,
  allowOnlyNumbers,
} from "../../utils/inputUtils";

interface FormBuilderProps {
  questions: ProposalQuestions[];
  member?: string;
  parent?: string;
  nestingLevel?: number;
  prevParent?: string;
}
export const reduceObj = (obj: any, key: string) => {
  const keys = key.split(".");
  let temp = obj[keys[0]];
  for (let i = 1; i < keys.length; ++i) {
    if (temp) temp = temp[keys[i]];
    else return undefined;
  }
  return temp;
};
const getTextMaskProps = (textmask?: string) => {
  if (textmask) {
    if (textmask === "NUMBERS") {
      return {
        onInput: allowOnlyNumbers,
      };
    }
    if (textmask === "DOSAGE") {
      return {
        onInput: allowOnlyDosage,
      };
    }
    if (textmask === "ALPHANUMERICCUSTOM") {
      return {
        onInput: allowOnlyAlphaNumericCustom,
      };
    }
  }
  return {};
};
const FormBuilder: React.FC<FormBuilderProps> = ({
  questions,
  parent,
  member,
  nestingLevel = 0,
  prevParent,
}) => {
  const { values, handleBlur, errors } = useFormikContext<any>();
  const { proposalData } = useTypedSelector((state) => state.proposal);
  return (
    <>
      {questions.map((item) => {
        const Component = componentSchema[item.ANSWER_TYPE];
        if (!Component) {
          alert("Component not found for " + item.ANSWER_TYPE);
          return <></>;
        }
        const props = {
          label: item.QUESTION_TITLE,
          name: prevParent
            ? prevParent + "." + member + "." + item.QUESTION_ALIAS
            : member
            ? parent + "." + member + "." + item.QUESTION_ALIAS
            : item.QUESTION_ALIAS,
          isMultiple: item.allow_multiple,
          options: item.VALUE || [],
          ...(item.ANSWER_TYPE === "MONTH_YEAR" && {
            minDate: member
              ? moment(proposalData.insurer_details[member]?.dob, "YYYY-MM-DD")
              : undefined,
            maxDate: moment(),
          }),
          ...getTextMaskProps(item.TEXTMASK),
          maxLength: item.MAXLENGTH,
        };
        return (
          <Grid
            item
            xs={12}
            md={
              item.ANSWER_TYPE === "BOOLEAN" || item.ANSWER_TYPE === "TOGGLE"
                ? 12
                : 6
            }
          >
            <Component {...props} />
            {item.HAS_CHILD === "Y" && item.ANSWER_TYPE === "BOOLEAN" && (
              <>
                {reduceObj(values, props.name)?.members &&
                  reduceObj(values, props.name)?.members.map((member: any) => {
                    if (item.ADDITIONAL)
                      return (
                        <Grid container mt={1} spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              color="primary.main"
                              textTransform={"capitalize"}
                            >
                              {proposalData.insurer_details[member]?.first_name}
                            </Typography>
                          </Grid>
                          <FormBuilder
                            parent={item.QUESTION_ALIAS}
                            member={member}
                            questions={item.ADDITIONAL}
                            nestingLevel={nestingLevel + 1}
                          />
                        </Grid>
                      );
                    else return <></>;
                  })}
              </>
            )}
            {item.HAS_CHILD === "Y" &&
              item.ANSWER_TYPE === "TOGGLE" &&
              item.ADDITIONAL &&
              reduceObj(values, props.name) === "Y" && (
                <>
                  {" "}
                  <Grid container mt={1} spacing={2}>
                    <Grid item xs={12}>
                      <FormBuilder
                        parent={item.QUESTION_ALIAS}
                        member={member}
                        prevParent={parent}
                        questions={item.ADDITIONAL}
                        nestingLevel={nestingLevel + 1}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
          </Grid>
        );
      })}
    </>
  );
};

export default FormBuilder;
