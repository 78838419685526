import { Button, Divider, Grid, Typography } from "@mui/material";
import { Form, useFormikContext } from "formik";

import TextInput from "../../../InputComponents/TextInput";
import Toggle from "../../../InputComponents/Toggle";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import DateComp from "../../../InputComponents/DateComp";
import moment from "moment";

import Radio from "../../../InputComponents/Radio";
import Select from "../../../InputComponents/Select";
import {
  claim_reason,
  insuranceCompanyNames,
  reasonOptions,
} from "../options/care";
import { continuous_coverage_year_options } from "../options/star";
import { Add, Delete } from "@mui/icons-material";

const PortabilityCare = () => {
  const { values, submitForm, errors, setFieldValue } = useFormikContext();

  const { proposalData } = useTypedSelector((state) => state.proposal);
  console.log(errors, values);
  const isValidDate = moment(values.policy_expiry_date, "YYYY-MM-DD").isValid();
  return (
    <>
      <Form>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <DateComp label={"Policy Expiry Date"} name="policy_expiry_date" />
          </Grid>
          <Grid item md={6} xs={12}></Grid>

          {isValidDate && (
            <>
              <Grid item md={6} xs={12}>
                <Select
                  name="portability_reason"
                  label={"Reason For Porting"}
                  options={reasonOptions}
                />
              </Grid>
              <Grid item xs={12}>
                <Radio
                  label="Do you want the cumulative bonus to be converted to an enhanced sum Insured?"
                  name="convert_ci_to_si"
                  touched
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  options={insuranceCompanyNames}
                  touched
                  name={`previous_insurer`}
                  label="Previous Insurer Name"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  name={`previous_product_name`}
                  touched
                  label="Product Name"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  name={`previous_policy_number`}
                  touched
                  label="Previous Policy Number"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  options={[
                    {
                      OPTION_KEY: "1",
                      OPTION_VAL: "1 year",
                    },
                    {
                      OPTION_KEY: "2",
                      OPTION_VAL: "2 year",
                    },
                    {
                      OPTION_KEY: "3",
                      OPTION_VAL: "3 year",
                    },
                  ]}
                  touched
                  name={`policy_period`}
                  label="Policy Period"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput name={`addons`} touched label="Addons (if any)" />
              </Grid>
              <Grid item xs={12}>
                <Radio
                  name="is_longer_exclusion"
                  label="Whether the PED exclusions/time bound exclusions have longer exclusion period then the existing policy. "
                />
              </Grid>
              {values.is_longer_exclusion === "Y" && (
                <Grid item md={6} xs={12}>
                  <Select
                    name="difference_in_waiting_period"
                    label="Difference in Waiting period"
                    options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_item) => ({
                      OPTION_KEY: _item,
                      OPTION_VAL: `${_item} year`,
                    }))}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Toggle
                  name="members_covered_in_existing"
                  label="Which members are covered in existing policy?"
                />
              </Grid>
              {values.members_covered_in_existing &&
                values.members_covered_in_existing.members?.map((item) => (
                  <>
                    <Grid item xs={12} md={6}>
                      <Select
                        name={`member_data.${item}.continous_coverage_year`}
                        touched
                        options={continuous_coverage_year_options}
                        label="Continous Coverage Year"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <DateComp
                        label={"Date of enrollment"}
                        name={`member_data.${item}.date_of_enrollment`}
                      />
                    </Grid>
                    {[
                      ...Array(
                        values.member_data?.[item]?.continous_coverage_year
                      ),
                    ]?.map((_, coverage_count) => (
                      <>
                        <Grid item xs={12}>
                          <TextInput
                            label={
                              "Sum Insured in Year " + (coverage_count + 1)
                            }
                            name={`member_data.${item}.prev_sum_insured.${coverage_count}`}
                          />
                        </Grid>
                      </>
                    ))}
                    <Grid item xs={12}>
                      <TextInput
                        name={`member_data.${item}.ncb_bonus_earned`}
                        label="No Claim Bonus Earned"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Radio
                        label="Did you make any claim?"
                        touched
                        name={`member_data.${item}.is_claim_made`}
                        handleChange={(val) => {
                          if (val === "Y") {
                            setFieldValue(`member_data.${item}.claim_details`, [
                              {
                                claim_reason: "",
                                visit_date: "",
                                claim_amount: "",
                                select_insurer: "",
                                claim_number: "",
                                payment_date: "",
                              },
                            ]);
                          } else
                            setFieldValue(
                              `member_data.${item}.claim_details`,
                              []
                            );
                        }}
                      />
                    </Grid>
                    {values.member_data?.[item] &&
                      values.member_data?.[item]?.is_claim_made === "Y" &&
                      values.member_data?.[item]?.claim_details.map(
                        (claim_detail, claim_idx) => (
                          <>
                            <Grid item xs={12} md={6}>
                              <Select
                                name={`member_data.${item}.claim_details.${claim_idx}.reason_for_claim`}
                                label="Reason for claim"
                                options={claim_reason}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <DateComp
                                name={`member_data.${item}.claim_details.${claim_idx}.visit_date`}
                                label="Visit Date"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextInput
                                name={`member_data.${item}.claim_details.${claim_idx}.claim_amount`}
                                label="Claim Amount"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {" "}
                              <TextInput
                                name={`member_data.${item}.claim_details.${claim_idx}.claim_number`}
                                label="Claim Number"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Select
                                name={`member_data.${item}.claim_details.${claim_idx}.insurer`}
                                label="Insurer Name"
                                options={insuranceCompanyNames}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <DateComp
                                name={`member_data.${item}.claim_details.${claim_idx}.payment_date`}
                                label="Payment Date"
                              />
                            </Grid>
                            {values.member_data?.[item]?.claim_details?.length >
                              1 && (
                              <Grid
                                item
                                xs={12}
                                display={"flex"}
                                justifyContent={"center"}
                              >
                                <Button
                                  color="error"
                                  variant="outlined"
                                  startIcon={<Delete />}
                                  onClick={() => {
                                    setFieldValue(
                                      `member_data.${item}.claim_details`,
                                      values.member_data?.[
                                        item
                                      ]?.claim_details.filter(
                                        (_item, curr_idx) => {
                                          return curr_idx !== claim_idx;
                                        }
                                      )
                                    );
                                  }}
                                >
                                  Remove
                                </Button>
                              </Grid>
                            )}
                          </>
                        )
                      )}
                    {values.member_data?.[item]?.claim_details?.length < 3 && (
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        <Button
                          color="success"
                          variant="outlined"
                          startIcon={<Add />}
                          onClick={() => {
                            setFieldValue(`member_data.${item}.claim_details`, [
                              ...values.member_data?.[item]?.claim_details,
                              {
                                claim_reason: "",
                                visit_date: "",
                                claim_amount: "",
                                select_insurer: "",
                                claim_number: "",
                                payment_date: "",
                              },
                            ]);
                          }}
                        >
                          Add
                        </Button>
                      </Grid>
                    )}
                  </>
                ))}
            </>
          )}
          <Grid item xs={12} justifyContent="center" display={"flex"} my={1}>
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                submitForm();
              }}
            >
              {"Proceed to Review & Pay"}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default PortabilityCare;
