import DateComp from "../../pages/ProposalPage/components/InputComponents/DateComp";
import MonthYear from "../../pages/ProposalPage/components/InputComponents/MonthYear";
import Select from "../../pages/ProposalPage/components/InputComponents/Select";
import TextInput from "../../pages/ProposalPage/components/InputComponents/TextInput";
import Toggle from "../../pages/ProposalPage/components/InputComponents/Toggle";
import YesNo from "../../pages/ProposalPage/components/InputComponents/YesNo";

const componentSchema = {
  BOOLEAN: Toggle,
  DROPDOWN: Select,
  FREE_TEXT: TextInput,
  DATE: DateComp,
  TOGGLE: YesNo,
  MONTH_YEAR: MonthYear,
};

export default componentSchema;
