import {
  Box,
  Container,
  Grid,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton/BackButton";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useGetHealthProductByIdQuery } from "../../services/Landing.service";
import { useGetProposalDetailsQuery } from "../../services/Proposal.service";
import ProductSummary from "../Riders/components/ProductSummary";
import CKYCForm from "./components/Forms/CKYCForm";
import InsurerForm from "./components/Forms/InsurerForm";
import MedicalForm from "./components/Forms/MedicalForm";
import NomineeForm from "./components/Forms/NomineeForm";
import ProposerForm from "./components/Forms/ProposerForm";
import PlanCard from "./components/PlanCard";
import ProposalStepper from "./components/ProposalStepper";
import HeartLoader from "../../components/Loader/Loader";
import Portability from "./components/Forms/Portability";

const ProposalPage = () => {
  const { isProposalLoading } = useGetProposalDetailsQuery();
  const { activeProposal } = useTypedSelector((state) => state.proposal);
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <>
      <Container>
        {isProposalLoading && <HeartLoader message={""} />}
        <Paper
          sx={{
            boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.02)",
            borderRadius: "5px",
            px: 2,
            py: 3,
            [theme.breakpoints.down("md")]: {
              px: 1,
              py: 2,
            },
          }}
        >
          <BackButton
            label="Product Details"
            onClick={() => {
              navigate({
                pathname: "/product-summary",
                search: window.location.search,
              });
            }}
          />
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Paper
                sx={{
                  background: "#FFFFFF",
                  boxShadow: "0px 15px 20px 5px rgba(219, 219, 219, 0.25)",
                  p: 1,
                  [theme.breakpoints.down("md")]: {
                    p: 0,
                  },
                }}
              >
                <ProposalStepper />
                <Box
                  px={{
                    xs: 1,
                    md: 4,
                  }}
                  mt={{
                    xs: 1,
                    md: 2,
                  }}
                >
                  <ProposerForm />
                  <CKYCForm />
                  <InsurerForm />
                  <MedicalForm />
                  <NomineeForm />
                  <Portability />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <ProductSummary />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default ProposalPage;
