import { Box, Button, Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
interface SelectMenuProps {
  background?: "white" | "transparent";
  label?: string;
  selectedValue?: string;
  onClick: () => void;
}
const SelectMenu: React.FC<SelectMenuProps> = ({
  background = "transparent",
  label,
  selectedValue,
  onClick,
}) => {
  const theme = useTheme();
  return (
    <Button
      variant='contained'
      onClick={onClick}
      disableFocusRipple
      sx={{
        dispaly: "flex",
        flexDirection: "column",
        background: background,
        boxShadow: "none",
        justifyContent: "center",
        alignItems: "flex-start",
        "&:hover,&:focus": {
          background,
          boxShadow: "none",
        },
        "@media (max-width: 600px)": {
          width: "50%",
          justifyContent: "flex-start",
          px: "0px",
        },
      }}
    >
      {label && (
        <Typography
          fontSize={{
            xs: "12px",
            sm: "14px",
          }}
          fontWeight='400'
        >
          {label}
        </Typography>
      )}
      <Box
        display={"flex"}
        gap={{
          xs: "0px",
          sm: "8px",
        }}
        alignItems='center'
      >
        <Typography
          fontSize={{
            xs: "12px",
            sm: "15px",
          }}
          lineHeight={"15px"}
          fontWeight='500'
          color={
            background === "transparent" ? "text.secondary" : "text.primary"
          }
        >
          {selectedValue}
        </Typography>
        <ExpandMoreIcon
          sx={{
            color: "primary.main",
            fontSize: {
              xs: "16px",
              sm: "20px",
            },
          }}
        />
      </Box>
    </Button>
  );
};

export default SelectMenu;
