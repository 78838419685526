import {
  AppBar,
  Box,
  Button,
  Container,
  Link,
  Theme,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import logo from "../../assets/images/logo.png";
import support from "../../assets/images/support.png";
const Header = () => {
  return (
    <>
      <AppBar
        position='static'
        color='transparent'
        sx={{
          boxShadow: "none",
        }}
      >
        <Container>
          <Toolbar
            sx={{
              padding: "0 !important",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              display='flex'
              gap={"20px"}
              alignItems='center'
              sx={{
                "& img": {
                  width: {
                    xs: "60px",
                    sm: "100px",
                  },
                },
              }}
            >
              <img src={logo} alt='logo' />
            </Box>
            <Box
              display={"flex"}
              gap={{
                xs: "10px",
                sm: "20px",
              }}
            >
              <Button
                variant='text'
                href='tel:+919288009111'
                startIcon={
                  <>
                    <img width={"30px"} src={support} alt='support' />
                  </>
                }
              >
                <span>+91 92880 09111</span>
              </Button>
              <Button
                href='https://account.vkover.com'
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "16px",
                  },
                }}
                variant='outlined'
              >
                Login
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
