import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { closeViewPlanPopup } from "../../../../../../modules/quotes.slice";
import { Close, Download } from "@mui/icons-material";
import { useGetViewPlanDetails } from "../../../../../../services/Quotes.service";
import QuoteData from "./components/QuoteData";
import { commaSeparateNumber } from "../../../../../../utils/priceUtils";
import FeaturesList from "./components/FeaturesList";
import { features } from "process";

const ViewPlanPopup = () => {
  const { viewPlan } = useTypedSelector((state) => state.quote);
  const { open, quote_data, product_id } = viewPlan;
  const {
    highlights,
    coverage,
    value_added_services,
    additionalFeatures,
    waiting_periods,
    isPlanDetailsLoading,
    documents,
  } = useGetViewPlanDetails(product_id);
  const [activeTabValue, setActiveTabValue] = useState(0);
  const handleChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: any
  ) => {
    setActiveTabValue(value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (open || !isPlanDetailsLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open, isPlanDetailsLoading]);
  if (!open || isPlanDetailsLoading) return <></>;
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          backgroundColor: "white",
        }}
      >
        <Box>
          <Grid
            container
            sx={{
              background: "#212638",
              px: "60px",
              py: 4,
              position: "sticky",
              top: 0,
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                right: "0",
                top: "0px",
                color: "white",
              }}
              onClick={() => {
                dispatch(closeViewPlanPopup());
              }}
            >
              <Close />
            </IconButton>
            <Grid item xs={3}>
              <Box
                sx={{
                  "& img": {
                    height: "100px",
                    maxWidth: "160px",
                  },
                }}
              >
                <img alt="Insurance Company Logo" src={quote_data?.logo} />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Stack mt={2}>
                <QuoteData
                  label="Cover"
                  value={commaSeparateNumber(quote_data?.cover)}
                />
              </Stack>
            </Grid>
            <Grid item xs={3} mt={2}>
              <QuoteData
                label="Premium"
                value={commaSeparateNumber(quote_data?.premium)}
              />
            </Grid>
            {/* <Grid item xs={3} mt={2}>
              <QuoteData
                label='Claim Settlement Ratio'
                value={quote_data?.PRODUCT_DATA?.CLAIM_SETTLEMENT_RATIO}
              />
            </Grid> */}
          </Grid>
          <Box>
            <Tabs
              sx={{
                "& .MuiTabs-flexContainer": {
                  justifyContent: "center",
                },
                position: "sticky",
                top: "161px",
              }}
              value={activeTabValue}
              onChange={handleChange}
            >
              <Tab label="Highlights" />
              <Tab label="Coverage" />
              <Tab label="Waiting Periods" />
              <Tab label="Additional Features" />
              <Tab label="Value Added Services" />
              <Tab label="Document" />
            </Tabs>
          </Box>
          <Box
            mt={2}
            sx={{
              height: "calc(100vh - 220px)",
              overflow: "auto",
            }}
          >
            {activeTabValue === 0 && <FeaturesList features={highlights} />}
            {activeTabValue === 1 && <FeaturesList features={coverage} />}
            {activeTabValue === 2 && (
              <FeaturesList features={waiting_periods} />
            )}
            {activeTabValue === 3 && (
              <FeaturesList features={additionalFeatures} />
            )}
            {activeTabValue === 4 && (
              <FeaturesList features={value_added_services} />
            )}
            {activeTabValue === 5 && (
              <>
                <Stack gap="12px" mb="40px">
                  {documents?.map((feature) => (
                    <>
                      <Box
                        display="flex"
                        gap="20px"
                        px={"60px"}
                        alignItems={"center"}
                      >
                        <Box
                          sx={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "#F4F4F4",
                            minWidth: "100px",
                          }}
                        >
                          <Download
                            sx={{
                              width: "60px",
                              height: "60px",
                            }}
                            color="primary"
                          />
                        </Box>
                        <Grid container>
                          <Grid item xs={10}>
                            <Box>
                              <Typography
                                variant="h5"
                                color="#2D2C5F"
                                gutterBottom
                              >
                                {feature.FEATURE_TITLE}
                              </Typography>
                              <Typography variant="caption">
                                {feature.FEATURE_DESCRIPTION}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="contained"
                              href={feature.FEATURE_VAL}
                            >
                              Download
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  ))}
                </Stack>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ViewPlanPopup;
