import { Typography } from "@mui/material";

interface FormLabelProps {
  label: string;
}

const FormLabel: React.FC<FormLabelProps> = ({ label }) => {
  return (
    <>
      <Typography
        mb={{
          xs: 1,
          md: 2,
        }}
        sx={{
          fontWeight: 500,
          fontSize: {
            xs: "16px",
            md: "20px",
          },
          mt: {
            xs: "10px",
            md: "0px",
          },

          color: "#026E91",
        }}
      >
        {label}
      </Typography>
    </>
  );
};

export default FormLabel;
