import son from "../assets/images/son.svg";
import daughter from "../assets/images/daughter.svg";
import male from "../assets/images/male.svg";
import female from "../assets/images/female.svg";
import father from "../assets/images/father.png";
import mother from "../assets/images/mother.png";

import { MembersInterface } from "../modules/landing.slice";

const getMemberIcon = (member: string) => {
  switch (member) {
    case "son":
      return son;
    case "daughter":
      return daughter;
    case "male":
      return male;
    case "female":
      return female;
    case "father":
      return father;
    case "mother":
      return mother;
    default:
      return male;
  }
};
const getIcon = (
  member: MembersInterface["relation"],
  gender: MembersInterface["gender"]
): "male" | "female" | "son" | "daughter" | "father" | "mother" => {
  if (
    (member === "self" && gender === "female") ||
    (member === "spouse" && gender === "female")
  ) {
    return "female";
  } else if (
    (member === "self" && gender === "male") ||
    (member === "spouse" && gender === "male")
  ) {
    return "male";
  } else if (member !== "self" && member !== "spouse") return member;
  else return "male";
};
export { getMemberIcon, getIcon };
