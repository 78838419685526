import { useFormikContext } from "formik";
import YesNo from "../../InputComponents/YesNo";
import { Grid } from "@mui/material";
import TextInput from "../../InputComponents/TextInput";
import Select from "../../InputComponents/Select";
import { useGetCouncilMasterQuery } from "../../../../../services/Proposal.service";

const MedPractSection: React.FC<{
  key_name: string;
}> = ({ key_name }) => {
  const { values } = useFormikContext();
  const { council_names } = useGetCouncilMasterQuery();
  return (
    <>
      <Grid item xs={12}>
        <YesNo
          name={`${key_name}.is_medical_practitioner`}
          label='Do you have Medical Practitioner Id?'
        />
      </Grid>

      {
        //@ts-ignore
        values?.[key_name]?.is_medical_practitioner === "Y" && (
          <>
            <Grid item md={6} xs={12}>
              <TextInput
                label='Medical Practitioner Id'
                name={`${key_name}.MD_PRACTIONARY_ID`}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                options={council_names || []}
                label='Council Name'
                name={`${key_name}.COUNCIL_NAME`}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextInput
                label='Workplace Address'
                name={`${key_name}.WORKPLACE_ADDRESS`}
              />
            </Grid>
          </>
        )
      }
    </>
  );
};

export default MedPractSection;
