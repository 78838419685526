import { Chip, Divider, Grid, Typography } from "@mui/material";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import ProposalData from "../../pages/ProposalSummary/components/ProposalData";
import { ProposalQuestions } from "../../services/Proposal.service";
import { reduceObj } from "../FormBuilder/FormBuilder";
import moment from "moment";
interface SummaryBuilderProps {
  questions: ProposalQuestions[] | undefined;
  member?: string;
  parent?: string;
  nestingLevel?: number;
  prevParent?: string;
}
interface SummaryCompProps {
  item: any;
  label: string;
  name: string;
  proposalData: any;
  value: any;
  options: any;
}
const SummaryComp: React.FC<SummaryCompProps> = ({
  item,
  label,
  name,
  proposalData,
  value,
  options,
}) => {
  const fullWidth =
    item.ANSWER_TYPE === "BOOLEAN" || item.ANSWER_TYPE === "TOGGLE";

  if (item.ANSWER_TYPE === "BOOLEAN") {
    return (
      <ProposalData
        fullWidth={fullWidth}
        label={item.QUESTION_TITLE}
        value={
          <>
            {value?.members?.length ? (
              value?.members?.map((item: any) => (
                <Chip
                  sx={{
                    fontSize: {
                      xs: "12px",
                      md: "16px",
                    },
                  }}
                  label={proposalData.insurer_details[item]?.first_name}
                ></Chip>
              ))
            ) : (
              <Chip label='None' />
            )}
          </>
        }
      />
    );
  }
  if (item.ANSWER_TYPE === "TOGGLE")
    return (
      <ProposalData
        fullWidth={fullWidth}
        label={item.QUESTION_TITLE}
        value={value === "Y" ? "Yes" : "No"}
      />
    );
  if (item.ANSWER_TYPE === "DROPDOWN") {
    return (
      <ProposalData
        label={item.QUESTION_TITLE}
        value={
          item.VALUE?.find((item: any) => item.OPTION_KEY === value)?.OPTION_VAL
        }
      />
    );
  }
  if (item.ANSWER_TYPE === "MONTH_YEAR") {
    return (
      <ProposalData
        label={item.QUESTION_TITLE}
        value={moment(value, "YYYY-MM-DD").format("MMMM YYYY")}
      />
    );
  }
  return (
    <>
      <ProposalData label={item.QUESTION_TITLE} value={value} />
    </>
  );
};
const SummaryBuilder: React.FC<SummaryBuilderProps> = ({
  questions,
  parent,
  member,
  nestingLevel = 0,
  prevParent,
}) => {
  const { proposalData } = useTypedSelector((state) => state.proposal);
  const values = proposalData?.medical_details;
  if (!questions) return <></>;
  return (
    <>
      {questions.map((item) => {
        const props = {
          label: item.QUESTION_TITLE,
          name: prevParent
            ? prevParent + "." + member + "." + item.QUESTION_ALIAS
            : member
            ? parent + "." + member + "." + item.QUESTION_ALIAS
            : item.QUESTION_ALIAS,
          options: item.VALUE || [],
        };
        return (
          <Grid item xs={12}>
            <SummaryComp
              item={item}
              {...props}
              proposalData={proposalData}
              value={reduceObj(values, props.name)}
            />
            {item.HAS_CHILD === "Y" && item.ANSWER_TYPE === "BOOLEAN" && (
              <>
                {reduceObj(values, props.name)?.members &&
                  reduceObj(values, props.name)?.members.map((member: any) => {
                    if (item.ADDITIONAL)
                      return (
                        <>
                          <Grid container mt={1} spacing={2}>
                            <Grid item xs={12}>
                              <Typography
                                color='primary.main'
                                textTransform={"capitalize"}
                                fontSize={{
                                  xs: "12px",
                                  md: "16px",
                                }}
                              >
                                {
                                  proposalData.insurer_details[member]
                                    ?.first_name
                                }
                              </Typography>
                            </Grid>
                            <SummaryBuilder
                              parent={item.QUESTION_ALIAS}
                              member={member}
                              questions={item.ADDITIONAL}
                              nestingLevel={nestingLevel + 1}
                            />
                          </Grid>
                        </>
                      );
                    else return <></>;
                  })}
              </>
            )}
            {item.HAS_CHILD === "Y" &&
              item.ANSWER_TYPE === "TOGGLE" &&
              item.ADDITIONAL &&
              reduceObj(values, props.name) === "Y" && (
                <>
                  {" "}
                  <Grid container mt={1} spacing={2}>
                    <Grid item xs={12}>
                      <SummaryBuilder
                        parent={item.QUESTION_ALIAS}
                        member={member}
                        prevParent={parent}
                        questions={item.ADDITIONAL}
                        nestingLevel={nestingLevel + 1}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
          </Grid>
        );
      })}
    </>
  );
};
export default SummaryBuilder;
