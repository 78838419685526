import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectMenu from "../../../../components/SelectMenu/SelectMenu";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { setActiveStep } from "../../../../modules/landing.slice";
import { getMembers } from "../../../../utils/familyUtils";
import { getIcon, getMemberIcon } from "../../../../utils/iconUtils";
import membersIcon from "./../../../../assets/images/members.svg";
const ToolBar = () => {
  const theme = useTheme();
  const [showMembers, setShowMembers] = useState(false);
  const { members } = useTypedSelector((state) => state.landing);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <Box
        display={"flex"}
        justifyContent='space-between'
        width='100%'
        mt={{
          xs: "8px",
          sm: "24px",
        }}
        sx={{
          backgroundColor: theme.palette.primary.light,
          px: 2,
          py: 1.5,
          borderRadius: "5px 5px 0px 0px",
        }}
      >
        <Box
          display={"flex"}
          gap={{
            xs: "10px",
            sm: "20px",
          }}
          alignItems={"center"}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "32px",
              height: "32px",
              background: theme.palette.primary.main,
              borderRadius: "50%",
            }}
          >
            <img src={membersIcon} alt='' />
          </Box>
          <Typography
            fontSize={{
              xs: "12px",
              sm: "16px",
            }}
            color='text.secondary'
          >
            Member
          </Typography>
          <Button
            variant='outlined'
            onClick={() => {
              setShowMembers(true);
            }}
            sx={{
              textTransform: "capitalize",
              fontSize: {
                xs: "12px",
                sm: "16px",
              },
            }}
          >
            Details
          </Button>
          <Typography
            fontSize={{
              xs: "12px",
              sm: "16px",
            }}
            color='text.secondary'
          >
            {getMembers(members)}
          </Typography>
        </Box>
      </Box>
      <Dialog
        open={showMembers}
        onClose={() => {
          setShowMembers(false);
        }}
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle
          sx={{
            position: "relative",
          }}
        >
          <Typography fontSize={"18px"} color='text.secondary'>
            Member Details
          </Typography>
          <IconButton
            sx={{
              position: "absolute",
              right: "10px",
              top: "8px",
            }}
            onClick={() => setShowMembers(false)}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
            {members.map((member) => (
              <Box
                display={"flex"}
                justifyContent='space-between'
                alignItems={"center"}
              >
                <Box display='flex' gap='20px' alignItems={"center"}>
                  <Box width='30px' height='30px'>
                    <img
                      src={getMemberIcon(
                        getIcon(member.relation, member.gender)
                      )}
                      alt=''
                      width={"100%"}
                      height={"100%"}
                    />
                  </Box>
                  <Typography
                    fontSize={"16px"}
                    textTransform='capitalize'
                    color='text.secondary'
                  >
                    {member.relation}
                  </Typography>
                </Box>
                <Typography fontSize={"16px"} color='text.primary'>
                  {member.age} years
                </Typography>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              navigate("/");
              dispatch(setActiveStep(0));
            }}
          >
            Edit Details
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ToolBar;
