import { FormHelperText, FormLabel, Grid } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React from "react";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useGetProposalDetailsQuery } from "../../../../services/Proposal.service";
interface ToggleProps {
  name: string;
  label: string;
}
const members = [
  {
    relation: "self",
  },
  {
    relation: "spouse",
  },
];
const reduceObj = (obj: any, key: string) => {
  const keys = key.split(".");
  let temp = obj[keys[0]];
  for (let i = 1; i < keys.length; ++i) {
    if (temp) temp = temp[keys[i]];
    else return undefined;
  }
  return temp;
};
const Toggle: React.FC<ToggleProps> = ({ name, label }) => {
  const { setFieldValue, values, handleBlur, errors } = useFormikContext<any>();

  const { enquiry } = useGetProposalDetailsQuery();
  const members = enquiry?.members;
  const { insurer_details } = useTypedSelector(
    (state) => state.proposal.proposalData
  );
  const setValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (values[name]?.members?.includes(e.target.name)) {
      setFieldValue(
        `${name}.members`,
        values[name]?.members.filter((item: string) => item !== e.target.name)
      );
      return;
    }
    if (values[name]?.members?.length)
      setFieldValue(`${name}.members`, [
        ...values[name].members,
        e.target.name,
      ]);
    else {
      setFieldValue(`${name}.members`, [e.target.name]);
    }
  };
  const clearSelected = () => {
    setFieldValue(`${name}.members`, []);
  };
  return (
    <>
      <FormLabel
        sx={{
          color: "#2D2C5F",
          fontWeight: "400",
          display: "inline-block",
          mt: 2,
          fontSize: "15px",
        }}
      >
        {label}
      </FormLabel>
      <Grid container spacing={2} mt={1}>
        {members?.map((member, index) => {
          const key_name = member.count
            ? member.relation + member.count
            : member.relation;
          return (
            <>
              <Grid item xs={6}>
                <input
                  className="custom_checkbox"
                  type="checkbox"
                  id={`${key_name}_${name}`.replace(/\./g, "")}
                  name={key_name}
                  onBlur={handleBlur}
                  checked={reduceObj(values, name)?.members?.includes(key_name)}
                  onChange={setValue}
                />
                <label
                  className="custom_checkbox_label"
                  htmlFor={`${key_name}_${name}`.replace(/\./g, "")}
                >
                  {insurer_details?.[key_name]?.first_name}
                </label>
              </Grid>
            </>
          );
        })}
        <Grid item xs={6}>
          <input
            className="custom_checkbox"
            type="checkbox"
            id={`${name}_clear`}
            name={`${name}_clear`}
            onBlur={handleBlur}
            checked={
              reduceObj(values, name)?.members &&
              !reduceObj(values, name)?.members?.length
            }
            onChange={clearSelected}
          />
          <label className="custom_checkbox_label" htmlFor={`${name}_clear`}>
            Not Applicable
          </label>
        </Grid>
      </Grid>
      {/*@ts-ignore*/}
      {errors[name]?.members && (
        <>
          {/*@ts-ignore*/}
          <FormHelperText
            sx={{
              color: "red",
            }}
          >
            {/*@ts-ignore*/}
            {errors[name]?.members}
          </FormHelperText>
        </>
      )}
    </>
  );
};

export default Toggle;
