import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import {
  closeFilterModal,
  setFilters,
} from "../../../../../modules/quotes.slice";
import { useGetQuotes } from "../../../../../services/Quotes.service";
import FilterModal from "./FilterModal";

const CompanyFilter = () => {
  const dispatch = useDispatch();
  const { preferred_insurer } = useTypedSelector(
    (state) => state.quote.filter_modal
  );
  const { preferred_insurer: preferred_insurer_value } = useTypedSelector(
    (state) => state.quote.filters
  );
  const { companiesWithLogos } = useGetQuotes();
  return (
    <>
      <FilterModal
        title='Preferred Insurer'
        noUpdate
        maxWidth='xs'
        open={preferred_insurer}
        onClose={() => {
          dispatch(
            closeFilterModal({
              key: "preferred_insurer",
            })
          );
        }}
      >
        {Object.values(companiesWithLogos).map((item) => (
          <FormControlLabel
            sx={{
              display: "block",
              border: "1px solid #ECECEC",
              borderRadius: "5px",
              mb: 1,
              p: 1,
              mr: 0,
              background: preferred_insurer_value.includes(item.value)
                ? "rgba(1, 109, 145, 0.31)"
                : "",
              "& .MuiFormControlLabel-label": {
                color: preferred_insurer_value.includes(item.value)
                  ? "#016D91"
                  : "",
                fontSize: "14px",
              },
            }}
            label={
              <>
                <span>{item.name}</span>
              </>
            }
            value={item.value}
            checked={preferred_insurer_value.includes(item.value)}
            control={<Checkbox />}
            onChange={(e, checked) => {
              if (checked)
                dispatch(
                  setFilters({
                    key: "preferred_insurer",
                    value: [...preferred_insurer_value, item.value],
                  })
                );
              else
                dispatch(
                  setFilters({
                    key: "preferred_insurer",
                    value: preferred_insurer_value.filter(
                      (_i) => _i !== item.value
                    ),
                  })
                );
            }}
          />
        ))}
      </FilterModal>
    </>
  );
};

export default CompanyFilter;
