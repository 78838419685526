import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
interface RiderSelectProps {
  options: string[];
  id: string;
  handleChange: (e: any) => void;
  value: string;
  label: string;
  disabled?: boolean;
}
const RiderSelect: React.FC<RiderSelectProps> = ({ label, ...props }) => {
  return (
    <FormControl
      sx={{
        marginTop: 3,
        width: "50%",
        minWidth: "100px",
      }}
      size='small'
    >
      <InputLabel id={props.id}>{label}</InputLabel>
      <Select
        labelId={props.id}
        label={label}
        onChange={props.handleChange}
        defaultValue={props.options[0]}
        value={props.value}
        disabled={props?.disabled}
      >
        {props.options.map((item) => (
          <MenuItem value={item}>{item}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RiderSelect;
