import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import ShadowBox from "../../../components/ShadowBox/ShadowBox";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import ProposalData from "./ProposalData";
import {
  useGetOccupationQuery,
  useGetPincodeMutation,
  useGetProposalDetailsQuery,
} from "../../../services/Proposal.service";
import { useEffect } from "react";
import { reduceOptionsValue } from "../../../utils/inputUtils";

const ProposerDetails = () => {
  const { quote_data } = useGetProposalDetailsQuery();
  const { occupation_list } = useGetOccupationQuery();
  const { proposalData } = useTypedSelector((state) => state.proposal);
  const proposer_details = proposalData?.proposer_details;
  const { cityOptions, getCityState } = useGetPincodeMutation();
  const { cityOptions: commCityOptions, getCityState: getCommCityState } =
    useGetPincodeMutation();

  useEffect(() => {
    if (proposer_details?.pincode) {
      getCityState({
        pincode: proposer_details?.pincode,
        company_alias: quote_data?.COMPANY_SLUG,
      });
    }
  }, [proposer_details?.pincode]);
  useEffect(() => {
    if (proposer_details?.comm_pincode) {
      getCommCityState({
        pincode: proposer_details?.comm_pincode,
        company_alias: quote_data?.COMPANY_SLUG,
      });
    }
  }, [proposer_details?.comm_pincode]);
  if (!proposer_details) return <></>;
  const name = `${proposer_details.first_name} ${proposer_details.last_name}`;
  const dob = proposer_details.dob;
  const gender = proposer_details.gender === "male" ? "Male" : "Female";
  const mobile = proposer_details.mobile_no;
  const email = proposer_details.email;
  const address = `${proposer_details.address_line_1}, ${
    proposer_details.address_line_2
  }, ${proposer_details.address_line_3 || ""}`;
  const city = cityOptions?.find(
    (item) => item.OPTION_KEY === proposer_details?.city
  )?.OPTION_VAL;
  const state = proposer_details.state;
  const pincode = proposer_details.pincode;
  const comm_address = `${proposer_details.comm_address_line_1}, ${
    proposer_details.comm_address_line_2
  }, ${proposer_details.comm_address_line_3 || ""}`;
  const comm_city = commCityOptions?.find(
    (item) => item.OPTION_KEY === proposer_details?.comm_city
  )?.OPTION_VAL;
  const comm_state = proposer_details.comm_state;
  const comm_pincode = proposer_details.comm_pincode;
  const height = `${proposer_details.feet}'${proposer_details.inches}"`;
  const weight = proposer_details.weight;
  const is_comm_same_as_above = proposer_details.is_comm_same_as_above
    ? "Yes"
    : "No";
  const occupation = proposer_details?.occupation;
  return (
    <ShadowBox px={2} py={2}>
      <Box>
        <Typography
          color='text.secondary'
          fontSize={{
            xs: "12px",
            md: "16px",
          }}
          mb={2}
        >
          Proposer Details
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <ProposalData label='Name' value={name} />
        <ProposalData
          label='Date of Birth'
          value={moment(dob).format("DD/MM/YYYY")}
        />
        <ProposalData
          label='Occupation'
          value={reduceOptionsValue(occupation_list, occupation)}
        />
        <ProposalData label='Gender' value={gender} />
        <ProposalData label='Mobile' value={mobile} />
        <ProposalData label='Email' value={email} />
        <ProposalData label='Address' value={address} />
        <ProposalData label='City' value={city} />
        <ProposalData label='State' value={state} />
        <ProposalData label='Pincode' value={pincode} />
        <ProposalData label='Height' value={height} />
        <ProposalData label='Weight' value={weight} />
        <ProposalData
          label='Is Communication Address same as above address?'
          value={is_comm_same_as_above}
          fullWidth
        />
        {!proposer_details.is_comm_same_as_above && (
          <>
            <ProposalData label='Address' value={comm_address} />
            <ProposalData label='City' value={comm_city} />
            <ProposalData label='State' value={comm_state} />
            <ProposalData label='Pincode' value={comm_pincode} />
          </>
        )}
      </Grid>
    </ShadowBox>
  );
};
export default ProposerDetails;
