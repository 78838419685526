import { CircularProgress, Stack, Typography } from "@mui/material";
import ShadowBox from "../../../../components/ShadowBox/ShadowBox";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useEffect, useMemo, useState } from "react";
import {
  RiderInterface,
  useGetRiders,
} from "../../../../services/Quotes.service";
import RiderCard from "./RiderCard";
import { useDispatch } from "react-redux";
import { setSelectedMembersList } from "../../../../modules/quotes.slice";

const RidersList = () => {
  const { selected_members_rider } = useTypedSelector((state) => state.quote);

  const {
    riders,
    isRidersLoading,
    isFetching,
    refetchRiders,
    riders_to_disable,
    riders_to_enable,
    riders_to_disable_slug,
  } = useGetRiders(selected_members_rider);
  const dispatch = useDispatch();

  const { selectedRiders } = useTypedSelector((state) => state.quote);
  const nonMandatoryRiders = selectedRiders.filter(
    (rider) => rider.IS_MANDETORY !== "Y"
  ) as RiderInterface[];
  const length = useMemo(
    () => nonMandatoryRiders.length || 0,
    [nonMandatoryRiders]
  );
  const sum_insured = useMemo(
    () =>
      nonMandatoryRiders
        .map((rider) => rider.SUM_INSURED)
        .filter((item) => !!item),
    [nonMandatoryRiders]
  );
  const stringifiedSumInsured = useMemo(
    () => sum_insured.join(","),
    [sum_insured]
  );
  useEffect(() => {
    if (length || length === 0 || !!stringifiedSumInsured) {
      refetchRiders();
    }
  }, [length, stringifiedSumInsured, refetchRiders]);
  if (isRidersLoading)
    return (
      <>
        <ShadowBox
          py={5}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </ShadowBox>
      </>
    );
  if (!riders.length) return <></>;
  return (
    <ShadowBox
      px={{
        xs: 1,
        sm: 5,
      }}
      py={2}
    >
      <Typography color="text.secondary" mb={2}>
        Riders
      </Typography>
      <Stack spacing={2}>
        {riders.map((rider) => (
          <RiderCard
            key={rider.ADD_ON_ID}
            rider={rider}
            isDisabled={
              riders_to_disable.some(
                (item: any) => +item === +rider.ADD_ON_ID
              ) ||
              riders_to_disable_slug.some(
                (item: string) => item === rider.ADD_ON_SLUG
              )
            }
            isEnabled={
              rider.CHECK_ENABLED
                ? riders_to_enable.some(
                    (item: any) => +item === +rider.ADD_ON_ID
                  )
                : true
            }
            isFetching={isFetching}
            refetchRiders={refetchRiders}
          />
        ))}
      </Stack>
    </ShadowBox>
  );
};
export default RidersList;
