import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import ShadowBox from "../../../components/ShadowBox/ShadowBox";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import ProposalData from "./ProposalData";

const NomineeDetails = () => {
  const { proposalData } = useTypedSelector((state) => state.proposal);
  const nominee_details = proposalData?.nominee_details;
  if (!nominee_details) return <></>;
  const name = `${nominee_details.nominee_name}`;
  const dob = nominee_details.nominee_dob;
  const contact = nominee_details.nominee_contact;
  const relation = nominee_details.nominee_relation_data;

  return (
    <ShadowBox px={2} py={2}>
      <Box>
        <Typography color='text.secondary' mb={2}>
          Nominee Details
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <ProposalData label="Nominee's Name" value={name} />
        <ProposalData
          label="Nominee's Date of Birth"
          value={moment(dob).format("DD/MM/YYYY")}
        />
        <ProposalData label="Nominee's Contact" value={contact} />
        <ProposalData label="Nominee's Relation" value={relation} />
      </Grid>
    </ShadowBox>
  );
};
export default NomineeDetails;
