import React from "react";
import { useDispatch } from "react-redux";
import { setActiveStep } from "../../modules/landing.slice";
import "./Stepper.scss";
interface StepperProps {
  activeStep: number;
}
const Stepper: React.FC<StepperProps> = ({ activeStep }) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="stepper_wrapper">
        {[0, 1, 2, 3].map((item) => (
          <div
            className={`stepper_step ${activeStep >= item ? "active" : ""}`}
            onClick={() => {
              if (activeStep >= item) dispatch(setActiveStep(item));
            }}
          >
            {item + 1}
          </div>
        ))}
      </div>
    </>
  );
};

export default Stepper;
