import { Button, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import {
  setActiveProposal,
  setProposalData,
} from "../../../../modules/proposal.slice";
import {
  useGetProposalDetailsQuery,
  useGetProposalQuestions,
  useUpdateProposalFormData,
} from "../../../../services/Proposal.service";
import FormLabel from "../FormLabel";
import * as yup from "yup";

import moment from "moment";
import FormBuilder from "../../../../components/FormBuilder/FormBuilder";
import { ValidationFactory } from "../../../../components/FormBuilder/FormBuilder.utils";
import ProposalData from "../../../ProposalSummary/components/ProposalData";
const MedicalForm = () => {
  const dispatch = useDispatch();
  const { activeProposal, proposalData } = useTypedSelector(
    (state) => state.proposal
  );
  const { enquiry, isCKYC } = useGetProposalDetailsQuery();
  const { questions } = useGetProposalQuestions();
  const Validation = new ValidationFactory();
  const { saveForm } = useUpdateProposalFormData();
  if (activeProposal !== (isCKYC ? 3 : 2) || !questions) return null;
  const validate = Validation.validate(questions);

  return (
    <>
      <FormLabel label="Great! Let’s start with proposer details" />

      <Formik
        initialValues={proposalData.medical_details || {}}
        onSubmit={(values) => {
          saveForm({
            medical_details: values,
            from_screen: "medical",
          });
          dispatch(
            setProposalData({
              medical_details: values,
            })
          );
        }}
        validate={validate}
      >
        {({ submitForm, errors, values }) => (
          <Form>
            <Grid container spacing={2}>
              <>
                <FormBuilder questions={questions} />
              </>
              <Grid
                item
                xs={12}
                justifyContent="center"
                display={"flex"}
                my={1}
              >
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  Proceed to Nominee Details
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MedicalForm;
