import {
  Box,
  CircularProgress,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import useUrlQuery from "../../../hooks/useUrlQuery";
import { setSelectedTenure } from "../../../modules/quotes.slice";
import { useUpdateProposalMutation } from "../../../services/Proposal.service";
import { commaSeparateNumber } from "../../../utils/priceUtils";

interface DiscountSelectProps {
  id: string;
  value: string;
  label: string;
  premium: string | undefined;
  isLoading?: boolean;
}
const DiscountSelect: React.FC<DiscountSelectProps> = ({
  id,
  value,
  label,
  isLoading,
  premium,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { updateProposal } = useUpdateProposalMutation();
  const { getUrlQuery } = useUrlQuery();
  const { selectedTenure } = useTypedSelector((state) => state.quote);
  const proposal_id = getUrlQuery("proposal_id");

  const onChange = (e: any) => {
    dispatch(setSelectedTenure(value));
    queryClient.invalidateQueries(["riders"]);
    queryClient.setQueryData(["proposal", proposal_id], (old_data: any) => {
      return {
        ...old_data,
        data: {
          ...old_data.data,
          data: {
            ...old_data.data.data,
            PROPOSAL: {
              ...old_data.data.data.PROPOSAL,
              TOTAL_PREMIUM: premium,
              NET_PREMIUM: premium,
            },
          },
        },
      };
    });
  };
  const isSelected = selectedTenure === value;

  if (isLoading) {
    return (
      <Label>
        <Skeleton variant="circular" width={"20px"} height={"20px"} />
        <Box display="flex" flexDirection={"column"}>
          <Skeleton variant="text" width="100px" />
          <Skeleton variant="text" width="100px" />
        </Box>
      </Label>
    );
  }

  return (
    <>
      <Radio
        type="radio"
        name="discount"
        id={id}
        checked={isSelected}
        onChange={onChange}
      />
      <Label htmlFor={id}>
        <RadioButton className="discount-radio" />
        <Box display="flex" flexDirection={"column"}>
          <Typography
            fontSize={{
              xs: "13px",
              sm: "16px",
            }}
            color="text.secondary"
            fontWeight="600"
          >
            {label}
          </Typography>
          <Box display="flex" gap="4px" justifyContent={"space-between"}>
            <Typography
              fontWeight={"500"}
              fontSize={{
                xs: "12px",
                sm: "15px",
              }}
            >
              Premium
            </Typography>
            <Typography
              fontWeight={"500"}
              fontSize={{
                xs: "12px",
                sm: "15px",
              }}
              color={"text.secondary"}
            >
              {commaSeparateNumber(premium)}
            </Typography>
          </Box>
        </Box>
      </Label>
    </>
  );
};

export default DiscountSelect;

const Radio = styled("input")(({ theme }) => ({
  display: "none",
  "&:checked + label": {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.main,
    "& .discount-radio": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const Label = styled("label")(({ theme }) => ({
  flex: "1 1",
  maxWidth: "33.33%",
  display: "flex",
  border: "1px solid #ECECEC",
  borderRadius: "5px;",
  height: "120px",
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  gap: theme.spacing(2),
  transition: theme.transitions.create(["background-color", "border-color"], {
    duration: theme.transitions.duration.short,
  }),
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
}));

const RadioButton = styled("div")(({ theme }) => ({
  width: "20px",
  height: "20px",
  border: "2px solid #ECECEC",
  borderRadius: "50%",
  position: "relative",
  transition: theme.transitions.create(["background-color"], {
    duration: theme.transitions.duration.shortest,
  }),
  "&:after": {
    content: "''",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    background: "#fff",
  },
}));
