import { Grid } from "@mui/material";
import QuoteData, {
  QuoteDataProps,
} from "../../Quotes/components/QuoteCard/components/QuoteData";

const ProposalData: React.FC<QuoteDataProps> = (props) => {
  return (
    <>
      <Grid item xs={12} md={props.fullWidth ? 12 : 4}>
        <QuoteData isProposal {...props} />
      </Grid>
    </>
  );
};
export default ProposalData;
