import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { setProposalData } from "../../../../modules/proposal.slice";
import { useGenerateCKYC } from "../../../../services/CKYC.service";
import { useUpdateProposalFormData } from "../../../../services/Proposal.service";
import {
  allowOnlyAlphabets,
  allowOnlyAlphabetsAndNumbers,
  allowOnlyPanCard,
} from "../../../../utils/inputUtils";
import * as yup from "yup";
import Select from "../InputComponents/Select";
import TextInput from "../InputComponents/TextInput";
import YesNo from "../InputComponents/YesNo";
const occupationOptions = [
  {
    OPTION_KEY: "1",
    OPTION_VAL: "Professional",
  },
  {
    OPTION_KEY: "2",
    OPTION_VAL: "Self Employed",
  },
  {
    OPTION_KEY: "3",
    OPTION_VAL: "Retired",
  },
  {
    OPTION_KEY: "4",
    OPTION_VAL: "Housewife",
  },
  {
    OPTION_KEY: "5",
    OPTION_VAL: "Others",
  },
];
const familyRelationOptions = [
  {
    OPTION_KEY: "1",
    OPTION_VAL: "Father",
  },
  {
    OPTION_KEY: "2",
    OPTION_VAL: "Spouse",
  },
  {
    OPTION_KEY: "3",
    OPTION_VAL: "Mother",
  },
];
const incomeSourceOptions = [
  {
    OPTION_KEY: "1",
    OPTION_VAL: "Salary",
  },
  {
    OPTION_KEY: "2",
    OPTION_VAL: "Business",
  },
  {
    OPTION_KEY: "3",
    OPTION_VAL: "Sale of Assets",
  },
  {
    OPTION_KEY: "4",
    OPTION_VAL: "Inheritance",
  },
];
const addressProofOptions = [
  {
    OPTION_KEY: "1",
    OPTION_VAL: "Passport",
  },
  {
    OPTION_KEY: "2",
    OPTION_VAL: "Voter Id",
  },
  {
    OPTION_KEY: "3",
    OPTION_VAL: "Driving License",
  },
  {
    OPTION_KEY: "4",
    OPTION_VAL: "Proof of Possession of Aadhar",
  },
  {
    OPTION_KEY: "5",
    OPTION_VAL: "NREGA Job Card",
  },
  {
    OPTION_KEY: "6",
    OPTION_VAL: "National Population Register",
  },
];
const documentMap = {
  1: "Passport",
  2: "Voter Id",
  3: "Driving License",
  4: "Proof of Possession of Aadhar",
  5: "NREGA Job Card",
  6: "National Population Register",
  7: "Pan",
  10: "CKYC Identifier",
};
const inlineValidationsMap = {
  1: {
    maxLength: "8",
    onInput: (e) => {
      allowOnlyPanCard(e);
    },
  },
  2: { maxLength: "20", onInput: allowOnlyAlphabetsAndNumbers },
  3: { maxLength: "20", onInput: allowOnlyAlphabetsAndNumbers },
  4: { maxLength: "165" },
  5: {
    maxLength: "20",
  },
  6: { maxLength: "20" },
  7: { maxLength: "10" },
  10: { maxLength: "14" },
};

const CKYCStarForm = () => {
  const { generateCKYC, isGeneratingCKYC } = useGenerateCKYC();
  const { saveForm } = useUpdateProposalFormData();
  const dispatch = useDispatch();
  const { activeProposal, proposalData } = useTypedSelector((state) => state.proposal);

  const proposer_details = proposalData?.proposer_details;
  const ckyc_details = proposalData?.ckyc_details;

  // Initialize form values
  const initialValues = {
    address_proof: "",
    isAnyonePep: "N",
    ...proposalData?.ckyc_details,
  };


  const handleFileChange = (e, setFieldValue, fieldName) => {
    console.log(e, 'Event triggered');
    const file = e.target.files[0];
    console.log(file, 'Selected file');
    
    if (file) {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const arrayBuffer = event.target.result;
  
        const binaryString = new Uint8Array(arrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        );
  
        console.log(binaryString, 'Binary String');
  
        setFieldValue(fieldName, file);
      };
  
      reader.readAsArrayBuffer(file); 
    }
  };
  
  

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape({
          isAnyonePep: yup.string(),
          pepRemarks: yup.string().nullable().when("isAnyonePep", {
            is: "Y",
            then: yup.string().required("Remarks is required"),
          }),
          incomeSourceId: yup.string().required("Source is required"),
          occupation: yup.string().required("Occupation is required"),
          familyMemberFirstName: yup.string().required("Family Member First Name is required"),
          familyMemberLastName: yup.string().required("Family Member Last Name is required"),
          familyRelationshipId: yup.string().required("Relation is required"),
          ...(!ckyc_details?.ckycId && {
            document_type: yup.string().required("Address Proof is required"),
            document_number: yup.string().required("Proof Id is required"),
            proposer_address_proof: yup
              .mixed()
              .required()
              .test("proposer_address_proof", "Max Size Limit is 5MB", (val) => val && val.size / 1024 / 1024 <= 5),
            proposer_photo: yup
              .mixed()
              .required()
              .test("proposer_photo", "Max Size Limit is 5MB", (val) => val && val.size / 1024 / 1024 <= 5),
          }),
        })}
        onSubmit={async (values) => {
          const ckyc = await generateCKYC({
            first_name: proposer_details.first_name,
            last_name: proposer_details.last_name,
            gender: proposer_details.gender,
            dob: proposer_details.dob,
            pan_no: proposer_details.pan_no,
            pincode: proposer_details.pincode,
            address_line_1: proposer_details.address_line_1,
            address_line_2: proposer_details.address_line_2,
            cityName: proposer_details.city,
            ckycId: ckyc_details?.ckycId,
            ...values,            });
          const ckycReferenceId = ckyc?.data?.return_data?.ckycReferenceId;
          const proposer_address_proof = ckyc?.data?.return_data?.proposer_address_proof;
          const proposer_photo = ckyc?.data?.return_data?.proposer_photo;

          saveForm({
            ckyc_details: {
              ...proposalData?.ckyc_details,
              ckycReferenceId,
              proposer_address_proof,
              proposer_photo,
              ...ckyc?.ckycId,
              ...values,
            },
            from_screen: "ckyc",
          });
          console.log("CKYC Data:", values?.proposer_photo);
          console.log(ckyc_details?.ckycId,'ckyc_details?.ckycIdckyc_details?.ckycId')
          dispatch(
            setProposalData({
              ckyc_details: {
                ...proposalData?.ckyc_details,
                proposer_address_proof,
                proposer_photo,
                ...values,
                ckycReferenceId,
              },
            })
          );
          
        }}
      >
        {({ handleChange, handleBlur, setFieldValue, values, submitForm }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <YesNo
                  label="Are you a PEP - Politically Exposed Person or Any insured members is PEP or related to PEP?"
                  name="isAnyonePep"
                />
              </Grid>
              {values.isAnyonePep === "Y" && (
                <>
                  <Grid item md={6} xs={12}>
                    <TextInput label="Remarks" name="pepRemarks" />
                  </Grid>
                  <Grid item md={6} xs={12}></Grid>
                </>
              )}
              <Grid item md={6} xs={12}>
                <Select name="incomeSourceId" options={incomeSourceOptions} label="Source of Income" />
              </Grid>
              <Grid item md={6} xs={12}>
                <Select name="occupation" options={occupationOptions} label="Occupation" />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextInput name="familyMemberFirstName" label="Family Member First Name" onInput={allowOnlyAlphabets} />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextInput name="familyMemberLastName" label="Family Member Last Name" onInput={allowOnlyAlphabets} />
              </Grid>
              <Grid item md={6} xs={12}>
                <Select name="familyRelationshipId" options={familyRelationOptions} label="Relation with Family Member" />
              </Grid>
              <Grid item md={6} xs={12}></Grid>
              {!ckyc_details?.ckycId && (
                <>
                  <Grid item md={6} xs={12}>
                    <Select name="document_type" options={addressProofOptions} label="Address Proof" />
                  </Grid>
                  {values.document_type && (
                    <Grid item md={6} xs={12}>
                      <TextInput
                        name="document_number"
                        key={documentMap[values.document_type]}
                        label={documentMap[values.document_type] || ""}
                        {...inlineValidationsMap[values.document_type]}
                      />
                    </Grid>
                  )}
                  <Grid item md={6} xs={12}>
                    <TextInput
                      type="file"
                      name="proposer_address_proof"
                      label="Upload Document"
                      onChange={(e) => handleFileChange(e, setFieldValue, "proposer_address_proof")}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextInput
                      type="file"
                      name="proposer_photo"
                      label="Upload Photo"
                      onChange={(e) => handleFileChange(e, setFieldValue, "proposer_photo")}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} justifyContent="center" display={"flex"} my={1}>
                <Box sx={{ position: "relative" }}>
                  <Button
                    size="large"
                    variant="contained"
                    disabled={isGeneratingCKYC}
                    onClick={() => submitForm()}
                  >
                    Proceed to Insurer Details
                  </Button>
                  {isGeneratingCKYC && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
 
};

export default CKYCStarForm;
