import { Container, useMediaQuery } from "@mui/material";
import { useGetHealthProductByIdQuery } from "../../services/Landing.service";
import Filters from "./components/Filters/Filters";
import QuoteContainer from "./components/QuoteContainer/QuoteContainer";
import QuoteCount from "./components/QuoteCount/QuoteCount";
import ToolBar from "./components/Toolbar/Toolbar";
import ViewPlanPopup from "./components/QuoteCard/components/ViewPlanPopup/ViewPlanPopup";
import SelectedCompare from "./components/SelectedCompare/SelectedCompare";

const Quotes = () => {
  const { isEnquiryLoading } = useGetHealthProductByIdQuery();
  const isMobile = useMediaQuery("(max-width:768px)");
  if (isEnquiryLoading) {
    return <></>;
  }
  return (
    <Container>
      <ToolBar />
      <Filters />
      <QuoteCount />
      <QuoteContainer />
      <ViewPlanPopup />
      <SelectedCompare />
    </Container>
  );
};

export default Quotes;
