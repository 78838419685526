import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import useUrlQuery from "../../hooks/useUrlQuery";
import {
  useGetQuote,
  useGetViewPlanDetails,
} from "../../services/Quotes.service";
import BackButton from "../../components/BackButton/BackButton";
import { Navigate, useNavigate } from "react-router-dom";

const Compare = () => {
  const { selected_plan_for_compare_product_ids } = useTypedSelector(
    (state) => state.quote
  );
  const { getUrlQuery } = useUrlQuery();
  const quote_id = getUrlQuery("quote_id");
  const navigate = useNavigate();
  const plans_to_map = [...selected_plan_for_compare_product_ids];
  if (!selected_plan_for_compare_product_ids.length)
    return (
      <Navigate
        to={{ pathname: "/quote", search: `quote_id=${quote_id}` }}
        replace
      />
    );
  return (
    <>
      <Container
        sx={{
          py: "20px",
        }}
      >
        <BackButton
          label="Quotes"
          onClick={() => {
            navigate({
              pathname: "/quote",
              search: `quote_id=${quote_id}`,
            });
          }}
        />
        <Typography mb={2} variant="h6" color="primary" align="center">
          Find the best plan that suits your needs
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12 / (plans_to_map.length + 1)}>
            <Typography fontSize={"18px"} color="text.secondary">
              Plan Details
            </Typography>
          </Grid>
          {selected_plan_for_compare_product_ids.map((item) => (
            <>
              <Grid item xs={12 / (plans_to_map.length + 1)}>
                <ProductCard selected_plan={item} />
              </Grid>
            </>
          ))}
        </Grid>
        <FeaturesSection
          selected_plan_for_compare_product_ids={
            selected_plan_for_compare_product_ids
          }
        />
      </Container>
    </>
  );
};

export default Compare;
const ProductCard = ({ selected_plan }) => {
  const { getUrlQuery } = useUrlQuery();
  const quote_id = getUrlQuery("quote_id");
  const endpoint = selected_plan
    ? `/api/GetHealthQuotes/${selected_plan?.slug}/${selected_plan?.product_id}/${quote_id}/${selected_plan?.cover_amount}`
    : "";
  const { premium_data, product_data } = useGetQuote(endpoint);
  return (
    <>
      <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        border={"1px solid #ccc"}
        borderRadius={"8px"}
        padding={"16px"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          gap="12px"
          sx={{
            "& >img": {
              maxWidth: "120px",
              height: "60px",
            },
          }}
        >
          <img
            width={"120px"}
            height={"auto"}
            src={product_data?.LOGO_PATH}
            alt={product_data.INSURANCE_NAME}
          />
          <Box>
            <Typography fontSize="15px" color="text.secondary">
              {product_data?.PRODUCT_NAME}
            </Typography>
          </Box>
        </Box>
        <Button
          sx={{
            mt: 2,
          }}
          variant="contained"
        >
          ₹ {Number(premium_data.TOTAL_PREMIUM).toLocaleString("en-IN")}
        </Button>
      </Box>
    </>
  );
};

const FeaturesSection = ({ selected_plan_for_compare_product_ids }) => {
  const { viewPlanDetails } = useGetViewPlanDetails(
    selected_plan_for_compare_product_ids[0]?.product_id
  );
  const { viewPlanDetails: viewPlanDetails2 } = useGetViewPlanDetails(
    selected_plan_for_compare_product_ids?.[1]?.product_id
  );
  const { viewPlanDetails: viewPlanDetails3 } = useGetViewPlanDetails(
    selected_plan_for_compare_product_ids?.[2]?.product_id
  );
  const allPlanDetails = [viewPlanDetails, viewPlanDetails2, viewPlanDetails3];
  console.log(viewPlanDetails);

  return (
    <>
      {Object.values(viewPlanDetails?.data || {}).map((item, section_index) => (
        <>
          <Paper
            sx={{
              p: "12px",
              mt: 3,
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12 / (selected_plan_for_compare_product_ids.length + 1)}
              >
                <Typography fontSize={"20px"} color="primary">
                  {item.category}
                </Typography>
              </Grid>
              <Grid
                item
                xs={
                  12 - 12 / (selected_plan_for_compare_product_ids.length + 1)
                }
              ></Grid>
              {item?.features?.map((feature, feature_index) => (
                <>
                  <Grid
                    item
                    xs={12 / (selected_plan_for_compare_product_ids.length + 1)}
                  >
                    <Typography fontSize={"18px"} color="text.secondary">
                      {feature.FEATURE_TITLE}
                    </Typography>
                  </Grid>
                  {selected_plan_for_compare_product_ids.map((_, index) => (
                    <>
                      <Grid
                        item
                        xs={
                          12 /
                          (selected_plan_for_compare_product_ids.length + 1)
                        }
                      >
                        {
                          allPlanDetails[index]?.data?.[section_index]
                            ?.features?.[feature_index]?.FEATURE_VAL
                        }
                      </Grid>
                    </>
                  ))}
                </>
              ))}
            </Grid>
          </Paper>
        </>
      ))}
    </>
  );
};
