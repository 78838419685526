import {
  Box,
  Button,
  CircularProgress,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { setProposalData } from "../../../../modules/proposal.slice";
import {
  useGenerateCKYC,
  useUploadCignaDocMutation,
} from "../../../../services/CKYC.service";
import { useUpdateProposalFormData } from "../../../../services/Proposal.service";
import {
  allowOnlyAlphabets,
  allowOnlyAlphabetsAndNumbers,
  allowOnlyPanCard,
} from "../../../../utils/inputUtils";
import * as yup from "yup";
import Select from "../InputComponents/Select";
import TextInput from "../InputComponents/TextInput";
import YesNo from "../InputComponents/YesNo";

const addressProofOptions = [
  {
    OPTION_KEY: "PASSPORT",
    OPTION_VAL: "Passport",
  },
  {
    OPTION_KEY: "VOTEID",
    OPTION_VAL: "Voter Id",
  },
  {
    OPTION_KEY: "DLICESSE",
    OPTION_VAL: "Driving License",
  },
  {
    OPTION_KEY: "AADHARCARD",
    OPTION_VAL: "Aadhar Card",
  },

  {
    OPTION_KEY: "PHONEBILL",
    OPTION_VAL: "Recent Telephone Bill",
  },
  {
    OPTION_KEY: "BANKPASSBOOK",
    OPTION_VAL: "Bank Passbook or Statement",
  },
  {
    OPTION_KEY: "UTILITYBILL",
    OPTION_VAL: "Utility Bills",
  },
  {
    OPTION_KEY: "ACCOMODATIONLETTER",
    OPTION_VAL: "Accomodation allotment letter from Govt. Dept.",
  },
  {
    OPTION_KEY: "PROPERTYTAX",
    OPTION_VAL: "Property or Municipal tax receipt",
  },
];
const identityProofOptions = [
  {
    OPTION_KEY: "PANCARD",
    OPTION_VAL: "Pan Card",
  },
  {
    OPTION_KEY: "PASSPORT",
    OPTION_VAL: "Passport",
  },
  {
    OPTION_KEY: "VOTEID",
    OPTION_VAL: "Voter Id",
  },
  {
    OPTION_KEY: "DLICESSE",
    OPTION_VAL: "Driving License",
  },
  {
    OPTION_KEY: "JOBCARD",
    OPTION_VAL: "Job Card issued by NREGA",
  },
  {
    OPTION_KEY: "LETTERHEAD",
    OPTION_VAL: "Letter Issued by National Population Register",
  },
  {
    OPTION_KEY: "GAZETTEOFFICER",
    OPTION_VAL:
      "Letter from Gazetted Officer with duly attested photograph of customer",
  },
  {
    OPTION_KEY: "AADHARCARD",
    OPTION_VAL: "Aadhar Card",
  },
];

const CKYCCignaForm = () => {
  const { saveForm } = useUpdateProposalFormData();
  const dispatch = useDispatch();
  const { activeProposal, proposalData } = useTypedSelector(
    (state) => state.proposal
  );
  const proposer_details = proposalData?.proposer_details;
  const ckyc_details = proposalData?.ckyc_details;
  const { upload } = useUploadCignaDocMutation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Formik
        initialValues={{
          address_document_type: "",
          identity_document_type: "",
          proposer_address_proof: "",
          proposer_identity_proof: "",
          proposer_photo: "",
        }}
        validationSchema={yup.object().shape({
          address_document_type: yup
            .string()
            .required("This Field is required"),
          identity_document_type: yup
            .string()
            .required("This Field is required"),
          proposer_address_proof: yup
            .mixed()
            .required()
            .test(
              "address_proof_doc",
              "Max Size Limit is 5MB",
              (val) => val && val?.size / 1024 / 1024 <= 5
            ),
          proposer_identity_proof: yup
            .mixed()
            .required()
            .test(
              "proposer_photo",
              "Max Size Limit is 5MB",
              (val) => val && val?.size / 1024 / 1024 <= 5
            ),
          proposer_photo: yup
            .mixed()
            .required()
            .test(
              "proposer_photo",
              "Max Size Limit is 5MB",
              (val) => val && val?.size / 1024 / 1024 <= 5
            ),
        })}
        onSubmit={async (values) => {
          const res = await upload(values);
          saveForm({
            ckyc_details: res?.data?.return_data,
            from_screen: "ckyc",
          });
          dispatch(
            setProposalData({
              ckyc_details: res?.data?.return_data,
            })
          );
        }}
      >
        {({ handleChange, handleBlur, setFieldValue, values, submitForm }) => (
          <Form>
            {console.log(values)}
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Select
                  name="address_document_type"
                  options={addressProofOptions}
                  label="Address Proof"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {values.address_document_type && (
                  <TextInput
                    type="file"
                    name="address_proof_doc"
                    label="Upload Document"
                    onChange={(e) =>
                      setFieldValue("proposer_address_proof", e.target.files[0])
                    }
                  />
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Select
                  name="identity_document_type"
                  options={identityProofOptions}
                  label="Identity Proof"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {values.identity_document_type && (
                  <TextInput
                    type="file"
                    name="identity_proof_doc"
                    label="Upload Document"
                    onChange={(e) =>
                      setFieldValue(
                        "proposer_identity_proof",
                        e.target.files[0]
                      )
                    }
                  />
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextInput
                  type="file"
                  name="proposer_photo"
                  label="Upload Photo"
                  onChange={(e) =>
                    setFieldValue("proposer_photo", e.target.files[0])
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                display={"flex"}
                my={1}
              >
                <Box sx={{ position: "relative" }}>
                  <Button
                    size={isMobile ? "medium" : "large"}
                    variant="contained"
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Proceed to Insurer Details
                  </Button>
                  {false && (
                    <CircularProgress
                      size={isMobile ? 18 : 24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CKYCCignaForm;
