import React from "react";
import { Box } from "@mui/material";
import "./Loader.scss";
interface HeartLoaderProps {
  message?: string;
}
const HeartLoader: React.FC<HeartLoaderProps> = ({ message }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        zIndex: 9999,
      }}
    >
      {!message && <div id='heart' />}
      <Box
        sx={{
          fontSize: "20px",
          color: "white",
          fontWeight: 600,
        }}
      >
        {message}
      </Box>
    </Box>
  );
};

export default HeartLoader;
