import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { setProposalData } from "../../../../modules/proposal.slice";
import {
  useGenerateCKYC,
  useUploadCignaDocMutation,
  useVerifyCareCKYC,
} from "../../../../services/CKYC.service";
import { useUpdateProposalFormData } from "../../../../services/Proposal.service";
import {
  allowOnlyAlphabets,
  allowOnlyAlphabetsAndNumbers,
  allowOnlyPanCard,
  regex,
} from "../../../../utils/inputUtils";
import * as yup from "yup";
import Select from "../InputComponents/Select";
import TextInput from "../InputComponents/TextInput";
import YesNo from "../InputComponents/YesNo";
import { Check } from "@mui/icons-material";

const addressProofOptions = [
  {
    OPTION_KEY: "1",
    OPTION_VAL: "Passport",
  },
  {
    OPTION_KEY: "2",
    OPTION_VAL: "Voter Id",
  },
  {
    OPTION_KEY: "3",
    OPTION_VAL: "Driving License",
  },
  {
    OPTION_KEY: "7",
    OPTION_VAL: "Pan",
  },
];

const CKYCCareForm = () => {
  const { saveForm, isSaving } = useUpdateProposalFormData();
  const dispatch = useDispatch();
  const { activeProposal, proposalData } = useTypedSelector(
    (state) => state.proposal
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { upload } = useUploadCignaDocMutation();
  const { verifyCareCKYC, isVerifyCareCKYC } = useVerifyCareCKYC();
  const handleVerifyPan = async () => {
    const { data } = await verifyCareCKYC({
      pan_no: proposer_details?.pan_no,
      dob: proposer_details?.dob,
    });
    dispatch(
      setProposalData({
        ckyc_details: {
          ...proposalData?.ckyc_details,
          ...data?.return_data,
          status: data?.status,
        },
      })
    );
  };
  const proposer_details = proposalData?.proposer_details;
  const ckyc_details = proposalData?.ckyc_details;
  return (
    <>
      <Formik
        initialValues={{
          pan_no: proposer_details?.pan_no,
        }}
        validationSchema={yup.object().shape({
          pan_no: yup
            .string()
            .required("PAN Number is required")
            .matches(regex.pan_no, "Please Enter a valid PAN Number"),
        })}
        onSubmit={async (values) => {
          if (!ckyc_details?.verification_status) {
            upload(values);
          }
          saveForm({
            ckyc_details: {
              ckyc_reference_id: ckyc_details?.ckycReferenceId,
            },
            from_screen: "ckyc",
          });
        }}
      >
        {({ handleChange, handleBlur, setFieldValue, values, submitForm }) => (
          <Form>
            <Grid container spacing={2}>
              <>
                <Grid item md={6} xs={12}>
                  <TextInput
                    label="Pan No."
                    name="pan_no"
                    onInput={allowOnlyPanCard}
                    readOnly
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  {ckyc_details?.verification_status ? (
                    <Chip
                      sx={{
                        mt: "36px",
                      }}
                      variant="outlined"
                      label="Verified"
                      icon={<Check />}
                      color="success"
                    />
                  ) : (
                    <Button
                      onClick={() => {
                        handleVerifyPan();
                      }}
                      disabled={isVerifyCareCKYC}
                      variant="contained"
                      sx={{ mt: "32px" }}
                      type="button"
                    >
                      {isVerifyCareCKYC ? (
                        <CircularProgress size="30px" />
                      ) : (
                        "Verify PAN No."
                      )}
                    </Button>
                  )}
                </Grid>
              </>
              {ckyc_details.status === 500 && (
                <>
                  <Grid item md={6} xs={12}>
                    <Select
                      name="address_document_type"
                      options={addressProofOptions}
                      label="Address Proof"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {values.address_document_type && (
                      <TextInput
                        type="file"
                        name="address_proof_doc"
                        label="Upload Document"
                        onChange={(e) =>
                          setFieldValue(
                            "proposer_address_proof",
                            e.target.files[0]
                          )
                        }
                      />
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Select
                      name="identity_document_type"
                      options={addressProofOptions}
                      label="Identity Proof"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {values.identity_document_type && (
                      <TextInput
                        type="file"
                        name="identity_proof_doc"
                        label="Upload Document"
                        onChange={(e) =>
                          setFieldValue(
                            "proposer_identity_proof",
                            e.target.files[0]
                          )
                        }
                      />
                    )}
                  </Grid>
                </>
              )}
              {(ckyc_details?.verification_status ||
                (values.proposer_identity_proof &&
                  values.proposer_address_proof)) && (
                <>
                  <Grid
                    item
                    xs={12}
                    justifyContent="center"
                    display={"flex"}
                    my={1}
                  >
                    <Box sx={{ position: "relative" }}>
                      <Button
                        size={isMobile ? "medium" : "large"}
                        variant="contained"
                        disabled={isSaving}
                        onClick={() => {
                          submitForm();
                        }}
                      >
                        {isSaving ? (
                          <CircularProgress size="30px" />
                        ) : (
                          "Proceed to Insurer Details"
                        )}
                      </Button>
                      {false && (
                        <CircularProgress
                          size={isMobile ? 18 : 24}
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CKYCCareForm;
