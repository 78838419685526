import { Typography } from "@mui/material";
import React from "react";
import ShadowBox from "../ShadowBox/ShadowBox";

const Title: React.FC<{
  title: string;
  children?: React.ReactNode;
}> = ({ title, children }) => {
  return (
    <>
      <ShadowBox px={1} py={1} br>
        <Typography fontSize={"16px"} color={"text.secondary"} fontWeight={500}>
          {title}
        </Typography>
        {children}
      </ShadowBox>
    </>
  );
};

export default Title;
