import { Button } from "@mui/material";
import ArrowBack from "@mui/icons-material/ChevronLeft";
interface BackButtonProps {
  onClick: () => void;
  label: string;
}

const BackButton: React.FC<BackButtonProps> = ({ onClick, label }) => {
  return (
    <>
      <Button
        sx={{
          color: "text.primary",
          mb: 1,
        }}
        variant='text'
        onClick={onClick}
      >
        <ArrowBack />
        {label}
      </Button>
    </>
  );
};

export default BackButton;
