const getAgesInRange = (min: number, max: number) => {
  const ages = [];
  for (let i = min; i <= max; i++) {
    ages.push(`${i} years`);
  }
  return ages;
};
export const getAgeByRelation = (
  relation: "self" | "spouse" | "son" | "daughter" | "father" | "mother"
) => {
  switch (relation) {
    case "self":
      return getAgesInRange(18, 100);
    case "spouse":
      return getAgesInRange(18, 100);
    case "son":
      return getAgesInRange(1, 30);
    case "daughter":
      return getAgesInRange(1, 30);
    case "father":
      return getAgesInRange(40, 100);
    case "mother":
      return getAgesInRange(40, 100);
    default:
      return getAgesInRange(18, 65);
  }
};
export { getAgesInRange };
