import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import {
  useGetHealthProductByIdQuery,
  useGetHealthProductMutation,
} from "../../../../../services/Landing.service";
import { sumInsuredOptions } from "../Filters.utils";
import { FilterItem } from "./FilterItem";
interface FilterModalProps {
  maxWidth: "sm" | "xs";
  open: boolean;
  onClose: () => void;
  title: string;
  children?: React.ReactNode;
  noUpdate?: boolean;
}
const FilterModal: React.FC<FilterModalProps> = ({
  title,
  children,
  ...props
}) => {
  const { request_data } = useGetHealthProductByIdQuery();
  const { getProducts } = useGetHealthProductMutation();
  const { filters } = useTypedSelector((state) => state.quote);
  const onApply = () => {
    if (!props.noUpdate)
      getProducts({
        ...request_data,
        ...filters,
        lob: "HEALTH",
      });
    props.onClose();
  };
  const onCancel = () => {
    props.onClose();
  };

  return (
    <Dialog {...props} disableEnforceFocus fullWidth>
      <DialogTitle
        sx={{
          position: "relative",
        }}
      >
        <Typography fontSize={"20px"} color='text.secondary'>
          {title}
        </Typography>
        <IconButton
          sx={{
            position: "absolute",
            right: "0",
            top: "0px",
          }}
          onClick={props.onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' fullWidth onClick={onCancel}>
          Cancel
        </Button>
        <Button variant='contained' fullWidth onClick={onApply}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterModal;
