import { useLocation, useNavigate } from "react-router-dom";

const useUrlQuery = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const getUrlQuery = (key: string) => {
    const val = query.get(key);
    if (val) {
      return val;
    } else return "";
  };
  const setUrlQuery = (key: string, value: string) => {
    query.set(key, value);
    navigate(`${location.pathname}?${query.toString()}`, {
      replace: true,
    });
  };

  return {
    getUrlQuery,
    setUrlQuery,
  };
};

export default useUrlQuery;
