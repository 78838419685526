import { Box, Stepper, Step, StepLabel, useTheme } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { setActiveProposal } from "../../../modules/proposal.slice";
import { useGetProposalDetailsQuery } from "../../../services/Proposal.service";

const ProposalStepper = () => {
  const { activeProposal } = useTypedSelector((state) => state.proposal);
  const dispatch = useDispatch();
  const { isCKYC, isPortability } = useGetProposalDetailsQuery();
  const form_names = ["Proposer", "Insured", "Medical", "Nominee"];
  if (isCKYC) {
    form_names.splice(1, 0, "CKYC");
  }
  if (isPortability) {
    form_names.push("Portability");
  }
  const theme = useTheme();
  return (
    <>
      <Stepper alternativeLabel>
        {form_names.map((item, idx) => {
          return (
            <Step
              sx={{
                "& .MuiStepConnector-root": {
                  left: "calc(-50% + 13px)",
                  right: "calc(50% + 13px)",
                  "& span": {
                    borderColor: "primary.main",
                    borderWidth: "2px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    left: "calc(-50% + 10px)",
                    right: "calc(50% + 10px)",
                    top: "9px",
                  },
                },
              }}
            >
              <StepLabel
                onClick={() => dispatch(setActiveProposal(idx))}
                StepIconProps={{
                  sx: {
                    color: activeProposal >= idx ? "primary.main" : "#fff",
                    backgroundColor:
                      activeProposal >= idx ? "primary.main" : "#fff",
                    border: "2px solid",
                    borderColor: "primary.main",
                    borderRadius: "50%",
                    p: 0,
                    "& text": {
                      fill: activeProposal >= idx ? "#fff" : "#016d91",
                    },
                    [theme.breakpoints.down("sm")]: {
                      "& text": {
                        fontSize: "14px",
                      },
                      width: "20px",
                      height: "20px",
                    },
                  },
                }}
                sx={{
                  "& .MuiStepLabel-label": {
                    mt: "6px !important",
                    color:
                      activeProposal >= idx ? "primary.main" : "text.primary",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "11px",
                    },
                  },
                }}
              >
                {item}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </>
  );
};

export default ProposalStepper;
