import React from "react";

interface InputSideEffectProps {
  effect: () => void;
  dependencies: any[];
  children: React.ReactNode;
}

const InputSideEffect: React.FC<InputSideEffectProps> = ({
  effect,
  dependencies,
  children,
}) => {
  React.useEffect(effect, dependencies);
  return <>{children}</>;
};

export default InputSideEffect;
