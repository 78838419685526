import { GetQuotesResponseInterface } from "../services/Quotes.service";

export const showAmountInWords = (amount: number | string | undefined) => {
  if (!amount) return "";
  const amountInString = amount.toString();
  if (amountInString.length >= 6) {
    return "₹ " + Number(amount) / 100000 + "L";
  }
  return "";
};

export const commaSeparateNumber = (val: number | string | undefined) => {
  if (val) {
    return "₹ " + Number(val).toLocaleString("en-IN");
  }
  return "";
};

export const sortByPrice = (
  a: GetQuotesResponseInterface["PREMIUM_DATA"]["PREMIUM_DATA"],
  b: GetQuotesResponseInterface["PREMIUM_DATA"]["PREMIUM_DATA"]
) => {
  return Number(a.TOTAL_PREMIUM) - Number(b.TOTAL_PREMIUM);
};
