import { Button, Divider, Grid, Typography } from "@mui/material";
import { Form, useFormikContext,Formik } from "formik";
import Select from "../../../InputComponents/Select";
import {
  continuous_coverage_year_options,
  insuranceCompanyNames,
  policy_type,
  prev_policy_type_option,
  reasonOptions,
} from "../options/star";
import TextInput from "../../../InputComponents/TextInput";
import Toggle from "../../../InputComponents/Toggle";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import DateComp from "../../../InputComponents/DateComp";
import moment from "moment";
import YesNo from "../../../InputComponents/YesNo";
import { allowOnlyNumbers } from "../../../../../../utils/inputUtils";
import { useEffect, useState } from "react";
import Radio from "../../../InputComponents/Radio";
import { setProposalData } from "../../../../../../modules/proposal.slice";
import { useGetProposalDetailsQuery, useUpdateProposalFormData } from '../../../../../../services/Proposal.service';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

const PortabilityStar = () => {
  const navigate = useNavigate();
  const { values,submitForm, errors } = useFormikContext();
  const { proposalData } = useTypedSelector((state) => state.proposal);
  const { isPortability } = useGetProposalDetailsQuery();
  const { saveForm } = useUpdateProposalFormData(() => {});
  const dispatch = useDispatch();
  console.log(errors, values);
  const intialValues = {
    ...proposalData?.portability_details,
    ...proposalData?.ckyc_details,
  }
  if (isPortability) {
    navigate({
      pathname: "/proposal-summary",
      search: window.location.search,
    });
  }
  const handleFileChange = (e, setFieldValue, fieldName) => {
    console.log(e, 'Event triggered');
    const file = e.target.files[0];
    console.log(file, 'Selected file');
    
    if (file) {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const arrayBuffer = event.target.result;
  
        const binaryString = new Uint8Array(arrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        );
  
        console.log(binaryString, 'Binary String');
  
        setFieldValue(fieldName, file);
      };
  
      reader.readAsArrayBuffer(file); 
    }
  };


  return (
    <>
    <Formik
   initialValues={intialValues}
   validationSchema = {yup.object().shape({
    proposer_address_proof: yup
    .mixed()
    .required()
    .test("proposer_address_proof", "Max Size Limit is 5MB", (val) => val && val.size / 1024 / 1024 <= 5),
  proposer_photo: yup
    .mixed()
    .required()
    .test("proposer_photo", "Max Size Limit is 5MB", (val) => val && val.size / 1024 / 1024 <= 5),
   })

   }
    onSubmit = {async(values)=>{
      saveForm({
        portability_details : values,
        proposer_photo: values?.proposer_photo,
        proposer_address_proof:values?.proposer_address_proof,
      });
      dispatch(
        setProposalData({
          portability_details : values,
          proposer_photo: values?.proposer_photo,
          proposer_address_proof:values?.proposer_address_proof,
        })
      );
    }}
    >
      {
        ({
          submitForm,values,setFieldValue})=>(
            <Form>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Select
                  name="portability_reason"
                  label={"Reason For Porting"}
                  options={reasonOptions}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                    <TextInput
                      type="file"
                      name="proposer_address_proof"
                      label="Upload Document"
                      onChange={(e) => handleFileChange(e, setFieldValue, "proposer_address_proof")}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextInput
                      type="file"
                      name="proposer_photo"
                      label="Upload Photo"
                      onChange={(e) => handleFileChange(e, setFieldValue, "proposer_photo")}
                    />
                  </Grid>
              {values.portability_reason === "other" && (
                <Grid item xs={12} md={6}>
                  <TextInput label="Please Specify" name="other_reason" />
                </Grid>
              )}
    
              <Grid item xs={12}>
                <Toggle
                  name="members_covered_in_existing"
                  label="Which members are covered in existing policy?"
                />
              </Grid>
    
              {values.members_covered_in_existing &&
                values.members_covered_in_existing.members?.map((item) => (
                  <>
                    {" "}
                    <Grid item xs={12}>
                      <Typography
                        mt={2}
                        color="primary.main"
                        textTransform={"capitalize"}
                      >
                        {proposalData.insurer_details[item]?.first_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Select
                        options={prev_policy_type_option}
                        touched
                        name={`member_data.${item}.previous_policy_type`}
                        label="Previous Policy Type"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Select
                        options={insuranceCompanyNames}
                        touched
                        name={`member_data.${item}.insurer_name`}
                        label="Insurer Name"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput
                        name={`member_data.${item}.product_name`}
                        touched
                        label="Product Name"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Select
                        options={policy_type}
                        name={`member_data.${item}.policy_type`}
                        touched
                        label="Policy Type"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DateComp
                        label="Policy Inception Date"
                        name={`member_data.${item}.policy_inception_date`}
                        touched
                        maxDate={moment().subtract(1, "year")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Select
                        name={`member_data.${item}.continous_coverage_year`}
                        touched
                        options={continuous_coverage_year_options}
                        label="Continous Coverage Year"
                      />
                    </Grid>
                    {[
                      ...Array(values.member_data?.[item]?.continous_coverage_year),
                    ]?.map((_, coverage_count) => (
                      <>
                        <Grid item xs={12}>
                          <Typography
                            mt={2}
                            color="primary.main"
                            textTransform={"capitalize"}
                          >
                            Coverage year {coverage_count + 1} (
                            {proposalData.insurer_details[item]?.first_name})
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextInput
                            label="Policy Number"
                            touched
                            name={`member_data.${item}.policy_details.${coverage_count}.policy_no`}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextInput
                            label="Customer Id"
                            touched
                            name={`member_data.${item}.policy_details.${coverage_count}.policy_cust_id`}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <DateComp
                            label="Policy From Date"
                            touched
                            name={`member_data.${item}.policy_details.${coverage_count}.policy_from_date`}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <DateComp
                            label="Policy End Date"
                            touched
                            name={`member_data.${item}.policy_details.${coverage_count}.policy_to_date`}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextInput
                            label="Sum Insured"
                            touched
                            name={`member_data.${item}.policy_details.${coverage_count}.sum_insured`}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextInput
                            label="Cumulative Bonus"
                            touched
                            name={`member_data.${item}.policy_details.${coverage_count}.cumulative_bonus`}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Radio
                            label="Did you make any claim?"
                            touched
                            name={`member_data.${item}.policy_details.${coverage_count}.is_claim_made`}
                          />
                        </Grid>
                        {values?.member_data?.[item]?.policy_details?.[
                          coverage_count
                        ]?.is_claim_made === "Y" && (
                          <>
                            <Grid item xs={12} md={6}>
                              <TextInput
                                touched
                                name={`member_data.${item}.policy_details.${coverage_count}.no_of_claims`}
                                onInput={allowOnlyNumbers}
                                maxLength={3}
                                label="No of Claims"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextInput
                                label="Total Claim Amount"
                                touched
                                name={`member_data.${item}.policy_details.${coverage_count}.claim_amount`}
                                onInput={allowOnlyNumbers}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextInput
                                label="Name of Illness"
                                touched
                                name={`member_data.${item}.policy_details.${coverage_count}.illness_claim`}
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    ))}
                  </>
                ))}
              <Grid item xs={12} justifyContent="center" display={"flex"} my={1}>
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  {"Proceed to Review & Pay"}
                </Button>
              </Grid>
            </Grid>
          </Form>
                )}
      </Formik>
      </>
  );
};

export default PortabilityStar;
