import { Summarize } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import ShadowBox from "../../../components/ShadowBox/ShadowBox";
import SummaryBuilder from "../../../components/SummaryBuilder/SummaryBuilder";
import { useGetProposalQuestions } from "../../../services/Proposal.service";

const MedicalDetails = () => {
  const { questions } = useGetProposalQuestions();
  if (!questions) return <></>;
  return (
    <ShadowBox px={2} py={2}>
      <Box>
        <Typography
          fontSize={{
            xs: "12px",
            md: "16px",
          }}
          color='text.secondary'
          mb={2}
        >
          Medical Details
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <SummaryBuilder questions={questions} />
      </Grid>
    </ShadowBox>
  );
};

export default MedicalDetails;
