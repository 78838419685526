import { Box, Stack, Typography } from "@mui/material";
import { IFeature } from "../../../../../../../services/Quotes.service";

const FeaturesList: React.FC<{
  features: IFeature[] | undefined;
}> = ({ features }) => {
  if (!features) return <></>;
  return (
    <Stack gap='12px' mb='40px'>
      {features.map((feature) => (
        <FeatureCard
          title={feature.FEATURE_TITLE}
          description={feature.FEATURE_DESCRIPTION}
          value={feature.FEATURE_VAL}
        />
      ))}
    </Stack>
  );
};

export default FeaturesList;

const FeatureCard: React.FC<{
  title: string;
  description: string;
  value?: string;
}> = ({ title, description, value }) => {
  return (
    <>
      <Box display='flex' gap='20px' px={"60px"}>
        <Box
          sx={{
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#F4F4F4",
            minWidth: "100px",
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='34'
            height='36'
            viewBox='0 0 34 36'
            fill='none'
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M21.4439 18.667C21.168 17.887 20.6572 17.2117 19.9817 16.7341C19.3062 16.2565 18.4992 16 17.6719 16V15H15.6719V16C14.611 16 13.5936 16.4214 12.8434 17.1716C12.0933 17.9217 11.6719 18.9391 11.6719 20C11.6719 21.0609 12.0933 22.0783 12.8434 22.8284C13.5936 23.5786 14.611 24 15.6719 24V28C14.8019 28 14.0609 27.445 13.7849 26.667C13.7439 26.5397 13.6779 26.4219 13.5906 26.3206C13.5033 26.2193 13.3966 26.1365 13.2768 26.0772C13.157 26.0178 13.0265 25.9831 12.8931 25.9751C12.7596 25.967 12.6259 25.9858 12.4998 26.0304C12.3737 26.0749 12.2579 26.1443 12.1591 26.2344C12.0603 26.3245 11.9806 26.4335 11.9246 26.5549C11.8687 26.6764 11.8377 26.8078 11.8334 26.9415C11.8292 27.0751 11.8518 27.2082 11.8999 27.333C12.1757 28.113 12.6866 28.7883 13.3621 29.2659C14.0376 29.7435 14.8446 30 15.6719 30V31H17.6719V30C18.7327 30 19.7502 29.5786 20.5003 28.8284C21.2504 28.0783 21.6719 27.0609 21.6719 26C21.6719 24.9391 21.2504 23.9217 20.5003 23.1716C19.7502 22.4214 18.7327 22 17.6719 22V18C18.0855 17.9999 18.489 18.128 18.8267 18.3668C19.1645 18.6055 19.4199 18.9431 19.5579 19.333C19.6462 19.5831 19.8302 19.7879 20.0695 19.9023C20.188 19.9589 20.3165 19.9917 20.4477 19.9987C20.5788 20.0056 20.71 19.9867 20.8339 19.943C20.9577 19.8993 21.0717 19.8316 21.1694 19.7438C21.2671 19.656 21.3465 19.5498 21.4031 19.4313C21.4598 19.3128 21.4925 19.1844 21.4995 19.0532C21.5065 18.9221 21.4876 18.7908 21.4439 18.667ZM15.6719 18C15.1414 18 14.6327 18.2107 14.2577 18.5858C13.8826 18.9609 13.6719 19.4696 13.6719 20C13.6719 20.5304 13.8826 21.0391 14.2577 21.4142C14.6327 21.7893 15.1414 22 15.6719 22V18ZM17.6719 28C18.2023 28 18.711 27.7893 19.0861 27.4142C19.4612 27.0391 19.6719 26.5304 19.6719 26C19.6719 25.4696 19.4612 24.9609 19.0861 24.5858C18.711 24.2107 18.2023 24 17.6719 24V28Z'
              fill='#016D91'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M5.82486 2.621C8.27886 1.42 12.3049 0 16.7109 0C21.0249 0 24.9449 1.361 27.3859 2.546L27.5239 2.613C28.2599 2.977 28.8539 3.321 29.2719 3.6L25.5779 9C34.0939 17.706 40.6719 35.997 16.7109 35.997C-7.25014 35.997 -0.849144 18.038 7.74086 9L4.07086 3.6C4.35386 3.415 4.71286 3.2 5.14086 2.972C5.35286 2.858 5.58086 2.741 5.82486 2.622V2.621ZM23.2039 8.928L26.1609 4.605C23.4109 4.803 20.1389 5.449 16.9889 6.361C14.7389 7.011 12.2389 6.912 9.92386 6.485C9.34049 6.37685 8.76112 6.2481 8.18686 6.099L10.1069 8.926C14.2219 10.391 19.0879 10.391 23.2039 8.928ZM8.95186 10.63C13.7669 12.49 19.5539 12.49 24.3689 10.628C26.3787 12.7467 28.06 15.1544 29.3569 17.771C30.7089 20.529 31.4449 23.286 31.3249 25.662C31.2089 27.955 30.3069 29.914 28.2469 31.37C26.0999 32.887 22.4889 33.997 16.7099 33.997C10.9249 33.997 7.29686 32.906 5.12986 31.406C3.05486 29.968 2.14386 28.036 2.01486 25.774C1.87986 23.424 2.59985 20.681 3.94686 17.904C5.23185 15.256 7.02486 12.707 8.95186 10.63ZM7.80186 3.916C8.60186 4.154 9.43786 4.361 10.2859 4.518C12.4359 4.914 14.5919 4.972 16.4319 4.439C18.5761 3.81434 20.7569 3.32307 22.9619 2.968C21.1219 2.414 18.9699 2 16.7099 2C13.2649 2 10.0519 2.961 7.80186 3.916Z'
              fill='#016D91'
            />
          </svg>
        </Box>
        <Box>
          <Typography color='#2D2C5F' gutterBottom>
            {title} : <span>{value}</span>
          </Typography>
          <Typography variant='caption'>{description}</Typography>
        </Box>
      </Box>
    </>
  );
};
