import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./MemberOption.scss";
import { getMemberIcon } from "../../utils/iconUtils";
import { Box } from "@mui/material";

interface MemberOptionProps {
  isSelected?: boolean;
  icon: "male" | "female" | "son" | "daughter" | "mother" | "father";
  label: string;
  showCount?: boolean;
  onIncreaseCount?: (count: number) => void;
  onDecreaseCount?: (count: number) => void;
  value?: any;
  initialCount?: number;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent, val: any, count?: number) => void;
}
const MemberOption: React.FC<MemberOptionProps> = ({
  isSelected,
  icon,
  onIncreaseCount,
  onDecreaseCount,
  onClick,
  value,
  initialCount,
  disabled,
  ...props
}) => {
  const [count, setCount] = useState(initialCount || 1);
  const increaseCount = (e: React.MouseEvent) => {
    if (count < 5) {
      setCount((prev) => prev + 1);
      onIncreaseCount && onIncreaseCount(count + 1);
    }
    e.stopPropagation();
  };
  const decreaseCount = (e: React.MouseEvent) => {
    if (count > 1) {
      setCount((prev) => prev - 1);
      onDecreaseCount && onDecreaseCount(count + 1);
    }
    e.stopPropagation();
  };

  return (
    <>
      <Box position={"relative"} width={"140px"}>
        <div
          className={`member-container ${disabled ? "disabled" : ""} ${
            isSelected ? "selected" : ""
          }`}
          onClick={(e) => {
            !disabled && onClick && value && onClick(e, value, count);
          }}
        >
          <div className='member-icon'>
            <img src={getMemberIcon(icon)} alt='' />
          </div>
          <div className='member-label'>{props.label}</div>

          {isSelected && props.showCount && (
            <div className='member-count-wrapper'>
              <div className='member-count-icon' onClick={increaseCount}>
                <AddIcon />
              </div>
              <span className='member-count'>{count}</span>
              <div className='member-count-icon' onClick={decreaseCount}>
                <RemoveIcon />
              </div>
            </div>
          )}
        </div>
      </Box>
    </>
  );
};

export default MemberOption;
