export const genderOptions = [
  {
    OPTION_KEY: "male",
    OPTION_VAL: "Male",
  },
  {
    OPTION_KEY: "female",
    OPTION_VAL: "Female",
  },
];

export const feetOptions = [
  {
    OPTION_KEY: "1",
    OPTION_VAL: "1 Feet",
  },
  {
    OPTION_KEY: "2",
    OPTION_VAL: "2 Feet",
  },
  {
    OPTION_KEY: "3",
    OPTION_VAL: "3 Feet",
  },
  {
    OPTION_KEY: "4",
    OPTION_VAL: "4 Feet",
  },
  {
    OPTION_KEY: "5",
    OPTION_VAL: "5 Feet",
  },
  {
    OPTION_KEY: "6",
    OPTION_VAL: "6 Feet",
  },
  {
    OPTION_KEY: "7",
    OPTION_VAL: "7 Feet",
  },
];

export const inchesOptions = [
  {
    OPTION_KEY: "0",
    OPTION_VAL: "0 Inches",
  },
  {
    OPTION_KEY: "1",
    OPTION_VAL: "1 Inches",
  },
  {
    OPTION_KEY: "2",
    OPTION_VAL: "2 Inches",
  },
  {
    OPTION_KEY: "3",
    OPTION_VAL: "3 Inches",
  },
  {
    OPTION_KEY: "4",
    OPTION_VAL: "4 Inches",
  },
  {
    OPTION_KEY: "5",
    OPTION_VAL: "5 Inches",
  },
  {
    OPTION_KEY: "6",
    OPTION_VAL: "6 Inches",
  },
  {
    OPTION_KEY: "7",
    OPTION_VAL: "7 Inches",
  },
  {
    OPTION_KEY: "8",
    OPTION_VAL: "8 Inches",
  },
  {
    OPTION_KEY: "9",
    OPTION_VAL: "9 Inches",
  },
  {
    OPTION_KEY: "10",
    OPTION_VAL: "10 Inches",
  },
  {
    OPTION_KEY: "11",
    OPTION_VAL: "11 Inches",
  },
];
