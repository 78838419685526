import { Container, Grid, Paper, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton/BackButton";
import {
  useGetProposalDetailsQuery,
  usePaymentQuery,
} from "../../services/Proposal.service";
import PaymentSummary from "../Riders/components/ProductSummary";
import InsurerDetails from "./components/InsurerDetails";
import MedicalDetails from "./components/MedicalDetails";
import NomineeDetails from "./components/NomineeDetails";
import ProductSummary from "./components/ProductSummary";
import ProposerDetails from "./components/ProposerDetails";
import HeartLoader from "../../components/Loader/Loader";

const ProposalSummary: React.FC = () => {
  const navigate = useNavigate();
  const [showRefreshMessage, setShowRefreshMessage] = useState(false);
  const { product } = useGetProposalDetailsQuery();
  const { initiatePayment } = usePaymentQuery({
    onCKYCIncomplete: () => {
      setShowRefreshMessage(true);
    },
  });
  useEffect(() => {
    if (product) initiatePayment();
  }, [product]);

  return (
    <>
      <Container>
        <Paper
          sx={{
            boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.02)",
            borderRadius: "5px",
            px: 2,
            py: 3,
          }}
        >
          <BackButton
            label="Proposal"
            onClick={() => {
              navigate({
                pathname: "/proposal",
                search: window.location.search,
              });
            }}
          />
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Stack spacing={2}>
                <ProductSummary />
                <ProposerDetails />
                <InsurerDetails />
                <MedicalDetails />
                <NomineeDetails />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <PaymentSummary />
            </Grid>
          </Grid>
        </Paper>
        {showRefreshMessage && (
          <HeartLoader message="Please refresh the page after completing CKYC" />
        )}
      </Container>
    </>
  );
};

export default ProposalSummary;
