import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ShadowBox from "../../../components/ShadowBox/ShadowBox";
import { useGetProposalDetailsQuery } from "../../../services/Proposal.service";
import { commaSeparateNumber } from "../../../utils/priceUtils";
import QuoteData from "./../../Quotes/components/QuoteCard/components/QuoteData";
const ProductSummary: React.FC = () => {
  const { product, quote_data } = useGetProposalDetailsQuery();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  if (!quote_data) return <></>;
  return (
    <>
      <ShadowBox>
        <Box
          sx={{
            background: "#DFF3F8",
            px: 2,
            py: 3,
            borderRadius: "5px 5px 0px 0px",
            fontWeight: 500,
            fontSize: "16px",
            color: "primary.main",
          }}
        >
          Almost there! Please review information before making payment
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",

            py: 5,
            "& img": {
              width: "100px",
            },
            [theme.breakpoints.down("md")]: {
              py: 2,
              "& img": {
                width: "60px",
              },
              flexWrap: "wrap",
            },
          }}
        >
          <img
            src={product?.LOGO_PATH}
            alt=''
            height='auto'
            style={{
              maxHeight: "100px",
            }}
          />
          <QuoteData
            label='Sum Assured'
            value={commaSeparateNumber(quote_data.SUM_INSURED)}
          />
          {!isMobile && (
            <QuoteData
              label='Network Hospital'
              value={quote_data?.SUM_INSURED}
            />
          )}

          <QuoteData
            label='Premium'
            primary
            value={commaSeparateNumber(quote_data?.NET_PREMIUM)}
          />
        </Box>
      </ShadowBox>
    </>
  );
};

export default ProductSummary;
