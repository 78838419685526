import { createSlice } from "@reduxjs/toolkit";

const proposalSlice = createSlice({
  name: "proposal",
  initialState: {
    activeProposal: 0,
    proposalData: {} as any,
  },
  reducers: {
    setActiveProposal: (state, action) => {
      state.activeProposal = action.payload;
    },
    setProposalData: (state, action) => {
      state.proposalData = {
        ...state.proposalData,
        ...action.payload,
      };
    },
  },
});

export default proposalSlice.reducer;

export const { setActiveProposal, setProposalData } = proposalSlice.actions;
