import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetQuotesResponseInterface,
  RiderInterface,
} from "../services/Quotes.service";

const quoteSlice = createSlice({
  name: "quote",
  initialState: {
    filters: {
      requested_si: "400000-500000",
      preferred_insurer: [] as string[],
      deductible: "0",
      existing_cover: "0",
    },
    filter_modal: {
      requested_si: false,
      preferred_insurer: false,
      deductible: false,
      existing_cover: false,
    },
    selectedTenure: "1",
    selectedRiders: [] as RiderInterface[],
    selected_members_rider: {} as Record<string | number, string[]>,
    showMorePlans: {} as any,
    viewPlan: {
      open: false,
      product_id: undefined as number | undefined,
      quote_data: undefined as
        | {
            logo: string | undefined;
            premium: string | undefined;
            cover: string | undefined;
          }
        | undefined,
    },
    selected_plan_for_compare_product_ids: [] as any,
    selected_members_list: [] as string[],
  },
  reducers: {
    setFilters: (
      state,
      action: PayloadAction<{
        key:
          | "requested_si"
          | "preferred_insurer"
          | "deductible"
          | "existing_cover";
        value: any;
      }>
    ) => {
      state.filters[action.payload.key] = action.payload.value;
    },
    openFilterModal: (
      state,
      action: PayloadAction<{
        key:
          | "requested_si"
          | "preferred_insurer"
          | "deductible"
          | "existing_cover";
      }>
    ) => {
      state.filter_modal[action.payload.key] = true;
    },
    closeFilterModal: (
      state,
      action: PayloadAction<{
        key:
          | "requested_si"
          | "preferred_insurer"
          | "deductible"
          | "existing_cover";
      }>
    ) => {
      state.filter_modal[action.payload.key] = false;
    },
    setSelectedTenure: (state, action: PayloadAction<string>) => {
      state.selectedTenure = action.payload;
    },
    setSelectedRiders: (state, action: PayloadAction<RiderInterface[]>) => {
      state.selectedRiders = action.payload;
    },
    setSelectedMembersList: (state, action) => {
      state.selected_members_list = action.payload;
    },
    addOrRemoveRider: (state, action: PayloadAction<RiderInterface>) => {
      const index = state.selectedRiders.findIndex(
        (rider) => rider.ADD_ON_ID === action.payload.ADD_ON_ID
      );
      if (index === -1) {
        state.selectedRiders.push(action.payload);
      } else {
        state.selectedRiders.splice(index, 1);
      }
    },

    updateRiderSumInsured: (
      state,
      action: PayloadAction<{
        ADD_ON_ID: number;
        SUM_INSURED: string;
        rider: RiderInterface;
      }>
    ) => {
      const index = state.selectedRiders.findIndex(
        (rider) => rider.ADD_ON_ID === action.payload.ADD_ON_ID
      );
      if (index !== -1) {
        state.selectedRiders[index].SUM_INSURED = action.payload.SUM_INSURED;
      } else {
        state.selectedRiders.push({
          ...action.payload.rider,
          SUM_INSURED: action.payload.SUM_INSURED,
        });
      }
    },
    setShowMorePlans: (state, action: PayloadAction<any>) => {
      state.showMorePlans[action.payload.key] = action.payload.value;
    },
    openViewPlanPopup: (
      state,
      action: PayloadAction<{
        product_id: number | undefined;
        quote_data: {
          logo: string | undefined;
          premium: string | undefined;
          cover: string | undefined;
        };
      }>
    ) => {
      state.viewPlan.open = true;
      state.viewPlan.product_id = action.payload.product_id;
      state.viewPlan.quote_data = action.payload.quote_data;
    },
    closeViewPlanPopup: (state) => {
      state.viewPlan = {
        open: false,
        product_id: 0,
        quote_data: undefined,
      };
    },
    setPlansForCompare: (state, action) => {
      state.selected_plan_for_compare_product_ids = action.payload;
    },
    updatePlansForCompare: (state, action) => {
      if (
        state.selected_plan_for_compare_product_ids.some(
          (item: any) =>
            item.product_id === action.payload.product_id &&
            item.cover_amount === action.payload.cover_amount
        )
      ) {
        state.selected_plan_for_compare_product_ids =
          state.selected_plan_for_compare_product_ids.filter(
            (item: any) =>
              item.product_id !== action.payload.product_id ||
              item.cover_amount !== action.payload.cover_amount
          );
      } else if (state.selected_plan_for_compare_product_ids.length < 3)
        state.selected_plan_for_compare_product_ids.push(action.payload);
    },
    setSelectedMembers: (state, action) => {
      state.selected_members_rider = action.payload;
    },
  },
});

export const {
  setFilters,
  openFilterModal,
  closeFilterModal,
  setSelectedTenure,
  addOrRemoveRider,
  setSelectedRiders,
  setShowMorePlans,
  updateRiderSumInsured,
  openViewPlanPopup,
  closeViewPlanPopup,
  setPlansForCompare,
  updatePlansForCompare,
  setSelectedMembersList,
  setSelectedMembers,
} = quoteSlice.actions;
export default quoteSlice.reducer;
