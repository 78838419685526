import { FormHelperText, FormLabel, Grid } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React from "react";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useGetProposalDetailsQuery } from "../../../../services/Proposal.service";
interface ToggleProps {
  name: string;
  label: string;
}
const members = [
  {
    relation: "self",
  },
  {
    relation: "spouse",
  },
];
const reduceObj = (obj: any, key: string) => {
  const keys = key?.split(".");
  let temp = obj[keys[0]];
  for (let i = 1; i < keys.length; ++i) {
    if (temp) temp = temp[keys[i]];
    else return undefined;
  }
  return temp;
};
const YesNo: React.FC<ToggleProps> = ({ name, label }) => {
  const { setFieldValue, values, handleBlur, errors } = useFormikContext<any>();

  const value = reduceObj(values, name);
  const { enquiry } = useGetProposalDetailsQuery();
  const members = enquiry?.members;
  const { insurer_details } = useTypedSelector(
    (state) => state.proposal.proposalData
  );
  const setValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(`${name}`, e.target.value);
  };

  return (
    <>
      <FormLabel
        sx={{
          color: "#2D2C5F",
          fontWeight: "400",
          display: "inline-block",
          mt: 2,
          fontSize: "15px",
        }}
      >
        {label}
      </FormLabel>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <input
            className='custom_checkbox'
            type='checkbox'
            id={(name + "Y").replace(/\./g, "")}
            name={name}
            onBlur={handleBlur}
            checked={value === "Y"}
            onChange={setValue}
            value={"Y"}
          />
          <label
            className='custom_checkbox_label'
            htmlFor={(name + "Y").replace(/\./g, "")}
          >
            Yes
          </label>
        </Grid>
        <Grid item xs={6}>
          <input
            className='custom_checkbox'
            type='checkbox'
            id={(name + "N").replace(/\./g, "")}
            name={name}
            onBlur={handleBlur}
            checked={value === "N"}
            onChange={setValue}
            value={"N"}
          />
          <label
            className='custom_checkbox_label'
            htmlFor={(name + "N").replace(/\./g, "")}
          >
            No
          </label>
        </Grid>
        <FormHelperText error={true} sx={{ ml: 1 }}>
          <>{errors[name]}</>
        </FormHelperText>
      </Grid>
    </>
  );
};

export default YesNo;
