import {
  Box,
  Chip,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton/BackButton";
import ShadowBox from "../../components/ShadowBox/ShadowBox";
import PairData from "../../components/SummaryComponents/PairData";
import Title from "../../components/SummaryComponents/Title";
import { useGetProposalDetailsQuery } from "../../services/Proposal.service";
import { useGetMultiYearQuotes } from "../../services/Quotes.service";
import { commaSeparateNumber } from "../../utils/priceUtils";
import DiscountSelect from "./components/DiscountSelect";
import ProductSummary from "./components/ProductSummary";
import RidersList from "./components/RidersList/RidersList";
import ViewPlanPopup from "../Quotes/components/QuoteCard/components/ViewPlanPopup/ViewPlanPopup";
import { useDispatch } from "react-redux";
import { openViewPlanPopup } from "../../modules/quotes.slice";

const Riders = () => {
  const { isProposalLoading, product, quote_data } =
    useGetProposalDetailsQuery();
  const { discounts, isDiscountsLoading, isAnyDiscounts } =
    useGetMultiYearQuotes();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  if (isProposalLoading) return null;
  return (
    <>
      <Container>
        <Paper
          sx={{
            boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.02)",
            borderRadius: "5px",
            px: 2,
            py: 3,
          }}
        >
          <BackButton
            label='Quotes'
            onClick={() => {
              navigate({
                pathname: "/quote",
                search: `quote_id=${quote_data?.QUOTE_ID}`,
              });
            }}
          />
          <Grid container spacing={1}>
            <Grid item md={8} sm={12} xs={12}>
              <Stack spacing={2}>
                <ShadowBox
                  sx={{
                    display: "flex",
                    gap: "20px",
                    "& img": {
                      width: "140px",
                      height: "auto",
                      maxHeight: "100px",
                    },
                    py: { md: 4, xs: 1 },
                    px: { md: 5, xs: 2 },
                    [theme.breakpoints.down("sm")]: {
                      flexDirection: "column",
                      "& img": {
                        width: "100px",
                        height: "auto",
                        maxHeight: "100px",
                      },
                    },
                  }}
                >
                  <img src={product?.LOGO_PATH} alt='' />
                  <Box flexDirection={"column"} gap={"4px"} display={"flex"}>
                    <Typography
                      color={"text.secondary"}
                      fontSize={{
                        xs: "16px",
                        sm: "20px",
                      }}
                    >
                      {product?.PRODUCT_NAME}
                    </Typography>

                    <Box display='flex' gap='12px'>
                      <Chip
                        sx={{
                          fontSize: "14px",
                          backgroundColor: "primary.light",
                        }}
                        size='small'
                        variant='outlined'
                        color='primary'
                        label='See All Features'
                        onClick={() => {
                          dispatch(
                            openViewPlanPopup({
                              product_id: quote_data?.PRODUCT_ID,
                              quote_data: {
                                logo: product?.LOGO_PATH,
                                cover: quote_data?.SUM_INSURED,
                                premium: quote_data?.TOTAL_PREMIUM,
                              },
                            })
                          );
                        }}
                      />
                    </Box>
                  </Box>
                </ShadowBox>
                {(isAnyDiscounts || isDiscountsLoading) && (
                  <ShadowBox
                    sx={{ py: { md: 4, xs: 1 }, px: { md: 5, xs: 2 } }}
                  >
                    <Typography color='text.secondary' mb={2}>
                      Policy Period
                    </Typography>
                    <Box
                      display='flex'
                      gap='20px'
                      flexDirection={{
                        xs: "column",
                        md: "row",
                      }}
                    >
                      {discounts.map((item: any, idx) => (
                        <DiscountSelect
                          id={`${idx}_year`}
                          value={(idx + 1).toString()}
                          label={`${idx + 1} year`}
                          isLoading={isDiscountsLoading}
                          premium={item?.PREMIUM_DATA?.TOTAL_PREMIUM}
                        />
                      ))}
                    </Box>
                  </ShadowBox>
                )}

                <RidersList />
              </Stack>
            </Grid>
            <Grid item md={4} xs={12}>
              <ProductSummary />
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <ViewPlanPopup />
    </>
  );
};

export default Riders;
