import { combineReducers } from "@reduxjs/toolkit";
import landingSlice from "../modules/landing.slice";
import proposalSlice from "../modules/proposal.slice";
import quotesSlice from "../modules/quotes.slice";
const rootReducer = combineReducers({
  landing: landingSlice,
  quote: quotesSlice,
  proposal: proposalSlice,
});

export default rootReducer;
